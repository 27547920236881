import { urlConstants, dataConstants } from "@Constants"
import { userService, commonService } from "@Services"
import Api from "@Api/SMCApi"
import { logger } from '@Utilities';

export const eolService={
    getVersions, fetchDevices, getTechnologies, getDeviceCount
}

function logout(){
    let url = window.location.href;
    url = url.split('/')[0];
    if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}

function getTechnologies(){
    let url=urlConstants.SITE_URL
    url=url+urlConstants.EOL+'annotate?columnName=technology_name'
  // logger.debug(url)
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
        if(res.status!==401)
            return res.data
        else
            logout();
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
    })
}
function getNowStartDate(date=''){
  // logger.debug(date)
    let dd = new Date().getUTCDate();
    let mm = new Date().getUTCMonth() + 1;
    let yyyy = new Date().getUTCFullYear();
    let h = new Date().getUTCHours();
    if(date){
        dd = date.getUTCDate();
        mm = date.getUTCMonth() + 1;
        yyyy = date.getUTCFullYear();
        h = date.getUTCHours();
        let m = date.getUTCMinutes();
        let s = date.getUTCSeconds();

        if (mm < 10) mm = '0' + mm
        if (dd < 10) dd = '0' + dd
        if(h<10) h='0'+h;
        if(m<10) m='0'+m;
        if(s<10) s='0'+s;
        date=yyyy+'-'+mm+'-'+dd+' '+h+':'+m+':'+s
        return date;
    }
    if (mm < 10) mm = '0' + mm
    if (dd < 10) dd = '0' + dd
    if(h<10) h='0'+h;
    date=yyyy+'-'+mm+'-'+dd+' 00:00:00'
    switch(true){
        case h >= 0 && h < 6: date=yyyy+'-'+mm+'-'+dd+' 00:00:00';break;
        case h >= 6 && h < 12: date=yyyy+'-'+mm+'-'+dd+' 06:00:00';break;
        case h >= 12 && h < 18: date=yyyy+'-'+mm+'-'+dd+' 12:00:00';break;
        case h >= 18 && h < 24: date=yyyy+'-'+mm+'-'+dd+' 18:00:00';break;
        default: date=yyyy+'-'+mm+'-'+dd+' 00:00:00'; break;
    }
    return date;
}
function getDataStartDate(){
    let url=urlConstants.SITE_URL+urlConstants.EOL+'?limit=1'
  // logger.debug(url)
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
      let date=new Date(res.data[0].time)
      date=getNowStartDate(date);
      return date;
    }).catch((error)=>{
      if(String(error).includes('401'))
      {
          logger.debug(error);
          logout();
      }
      else
          logger.debug(error);
    })
}
function getDeviceCount(technology,params){
    let url=urlConstants.SITE_URL+urlConstants.EOL
    url+='annotate?columnName=technology_name&whereClause=technology_name='+technology
    if(params)
    {
        params = params.replaceAll('eol__','end_of_life__');
        params = params.replaceAll('&','^');
        url+='^'+params;
    }
  // logger.debug(url);
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
        return res.data[0].device_count;
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
    });
}
function fetchDevices(technology,offset,params=''){
    return getDataStartDate().then(startDate=>{
        return getDevices(technology,startDate,commonService.getNextQuarterDate(startDate),params,offset);
    });
}
function getDevices(technology,startDate,endDate,params,offset){
    let url=urlConstants.SITE_URL+urlConstants.EOL
    url+='?technology_name='+technology
    url+='&offset='+offset+'&'+dataConstants.FILTER_START_DATE+'='+startDate+'&'+
          dataConstants.FILTER_END_DATE+'='+endDate+'&'+params;
  // logger.debug(url);
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
      return res;
    }).catch((error)=>{
      if(String(error).includes('401'))
      {
        logger.debug(error);
        logout();
      }
      else
        logger.debug(error);
    })
}
function getVersions(technology,startDate=''){
    let url=urlConstants.SITE_URL+urlConstants.EOL
    url+='annotate/?columnName=version&whereClause=technology_name='+technology
    if(startDate)
        url+='&startDate='+startDate
  // logger.debug(url)
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
        if(res.status!==401)
            return res.data
        else
            logout();
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
    })
}