import React,{useEffect, useState} from 'react';
import { userService } from '@Services';
import { Routes, Route } from 'react-router-dom';
import UseCaseMapPage from './components/UseCaseMapPage/UseCaseMapPage';
import WeightagePage from './components/WeightagePage/WeightagePage';
import BaselineConfigPage from './components/BaselineConfigPage/BaselineConfigPage';
import CreateUserPage from './components/CreateUserPage/CreateUserPage';
import { Loading } from '@CommonComponents/Loading/Loading';


function AdminPage(){
    const [adminStatus, setAdminStatus] = useState();
    useEffect(()=>{
        userService.checkAdmin().then((response)=>{
            if(response)
                setAdminStatus('Admin');
            else
                setAdminStatus('User')
        });
    },[]);
    return(
        <React.Fragment>
            {adminStatus?
            <React.Fragment>
                {adminStatus==='Admin'?
                    <Routes>
                        <Route path="/usecasemap" element={<UseCaseMapPage/> } />
                        <Route path="/weightage" element={<WeightagePage/> } />
                        <Route path="/baseline" element={<BaselineConfigPage/> } />
                        <Route path="/createuser" element={<CreateUserPage/> } />
                    </Routes>
                :<div>Sorry !!! You don't have Admin Priviledge</div>}
            </React.Fragment>
            :<Loading />}
        </React.Fragment>
    );
}

export default AdminPage;