import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table} from 'react-bootstrap';
import { taskService } from '@Services';
import Button from 'react-bootstrap/Button';
import classes from './style.module.css'
import styles from "@Utilities/scrollbar.module.css";
import { commonService } from '@Services';

function FixitModal(props){
    const allData = props.data;
    const [scheduleModal, setScheduleModal] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(getCurrentDate());
    const [incidentStatus, setIncidentStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showExsistModal, setShowExsistModal] = useState(false);
    const [showMainModal, setShowMainModal] = useState(props.show);
    const [incidentDetails, setIncidentDetails] = useState('');
    const [incidentCreated, setIncidentCreated] = useState('');
    
    function schedulingDate(event){
        setScheduleDate(event.target.value);
    }
    function createIncident(){
        taskService.createTask(allData[0]).then(res=>{
            let obj = {}
            // console.log(res);
            if(res.status===201){
                obj = {
                    "incident" : res.data["incident_number"],
                    "description" : res.data["short_description"],
                    "technology" : res.data["technology"],
                    "usecase" : res.data["usecase"],
                    "hostname" : res.data["hostname"],
                    "ip_address" : res.data["ip_address"],
                }
                // props.modalShow(false)
                setIncidentDetails(obj)
                setIncidentStatus(res.statusText);
                setShowModal(true);
                setIncidentCreated('Created');
            } else {
                setIncidentDetails(res.data.message);
                setIncidentStatus(res.statusText);
                setIncidentCreated('Failed To Create');
                setShowExsistModal(true);
                setShowMainModal(false);
            }
        })
    }
    function modalHide(){
        setShowModal(false);
        props.modalShow(false);
    }
    function modalExsistHide(){
        setShowExsistModal(false);
        props.modalShow(false);
    }
    function fixSchedule(){
        setScheduleModal(false);
        props.modalShow(false);
    }
    function getCurrentDate(){
        let d=new Date();
        let dd=d.getUTCDate();
        let mm=d.getUTCMonth()+1;
        let yy=d.getFullYear();
        let hh=d.getUTCHours();
        let min=d.getUTCMinutes();
        if(dd<10) dd='0'+dd;
        if(mm<10) mm='0'+mm;
        if(hh<10) hh='0'+hh;
        if(min<10) min='0'+min;
        d=yy+'-'+mm+'-'+dd+'T'+hh+':'+min
        return d
    }
    return(
        <React.Fragment>
            <Modal
                show={showMainModal}
                onHide={() => props.modalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Appling Automation BOT to Fix Vulnerability
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={classes.modalBody+' '+styles.scrollbar_smc}>
                    <h4>Below Mentioned Devices will be fixed::</h4>
                    <Table striped bordered hover variant="dark" style={{fontSize:'1.2em'}}>
                        <thead>
                            <tr>
                                <th>Technology</th>
                                <th>Usecase</th>
                                <th>System Name</th>
                                <th>IP Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allData.map((data,ind)=>(
                                <tr key={ind}>
                                    {Object.keys(data).map((tableData,i)=>(
                                        <React.Fragment key={i}>
                                                {(tableData==='technology')?<td>{data[tableData]}</td>:(tableData==='usecase')?<td>{data[tableData]}</td>:(tableData==='hostname')?<td>{data[tableData]}</td>:(tableData==='ip_address')?<td>{data[tableData]}</td>:<td>NA</td>}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={createIncident}>Create Incident</Button>
                    <Button variant="secondary" onClick={() => props.modalShow(false)}>Fix Now</Button>
                    <Button variant="primary" onClick={() => setScheduleModal(true)}>Schedule</Button>
                </Modal.Footer>
            </Modal>
            {scheduleModal?
                <Modal
                show={scheduleModal}
                onHide={() => setScheduleModal(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Choose Date and Time
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={classes.modalBody+' '+styles.scrollbar_smc}>
                        <form noValidate>
                            <div>
                                <label for="scheduledate">Choose Date and Time</label><br/>
                                <input type="datetime-local" id="scheduledate" name="scheduledate" 
                                value={scheduleDate} onChange={schedulingDate}/>
                            </div>
                            <br />
                            <Button 
                                variant="secondary"
                                onClick={fixSchedule}    
                            >
                                Set
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>
            :null}
            {incidentCreated?
                <React.Fragment>
                    {incidentCreated==='Created'?
                    <Modal
                    show={showModal}
                    onHide={modalHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                            Incident is Created
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className={classes.modalBody+' '+styles.scrollbar_smc}>
                                <h4>Incident Detail</h4>
                                <Table striped bordered hover variant="dark" style={{fontSize:'1.2em'}}>
                                    <thead>
                                        <tr>
                                            <th>Incident Number</th>
                                            <th>Description</th>
                                            <th>Technology</th>
                                            <th>Usecase</th>
                                            <th>System Name</th>
                                            <th>IP Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {Object.keys(incidentDetails).map((tableData,i)=>(
                                                <React.Fragment key={i}>
                                                        {(tableData==='incident')?<td>{incidentDetails[tableData]}</td>:(tableData==='description')?<td>{incidentDetails[tableData]}</td>:(tableData==='technology')?<td>{incidentDetails[tableData]}</td>:(tableData==='usecase')?<td>{incidentDetails[tableData]}</td>:(tableData==='hostname')?<td>{incidentDetails[tableData]}</td>:(tableData==='ip_address')?<td>{incidentDetails[tableData]}</td>:<td>NA</td>}
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                        
                                    </tbody>
                                </Table>
                            </Modal.Body>
                        </React.Fragment>                       
                    </Modal>                   
                    :<Modal
                    show={showExsistModal}
                    onHide={modalExsistHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                            Incident is {incidentStatus}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className={classes.modalBody+' '+styles.scrollbar_smc}>
                                <h4>{commonService.capitalizeletterWithDash(incidentDetails)}</h4>
                            </Modal.Body>
                        </React.Fragment>                     
                    </Modal>}
                </React.Fragment>
            :null}
        </React.Fragment>
    );
}

export default FixitModal;