import { dataService, commonService, csvService } from '@Services';
import React,{useEffect, useState, useRef} from 'react';
import { smcUtility } from '@Utilities';
import {FaFileDownload, FaFilePdf, FaFileCsv} from 'react-icons/fa';
import {LoadingFull} from '@CommonComponents/Loading/Loading';
import TechnologyRadarChart from '@CommonComponents/ECharts/TechnologyRadarChart/TechnologyRadarChart';
import classes from './TechnologyDetailsPage.module.css';
import {Container, Row, Col} from 'react-bootstrap';
import { FaSistrix } from 'react-icons/fa';
import TrenologyTrendChart from './Chart/TrenologyTrendChart';
import UseCaseModal from './components/UseCaseModal';
import Modal from 'react-bootstrap/Modal';
import styles from '@Utilities/scrollbar.module.css';
import DeviceList from '@CommonComponents/DeviceList/DeviceList';
import { Tooltip as ReactTooltip } from 'react-tooltip';


function TechnologyDetailsPage(props) {
    const [showDeviceSerachBox, setShowDeviceSerachBox] = useState(false);
    const [showDeviceList, setShowDeviceList] = useState(false);
    const [systemName, setSystemName] = useState();
    const [technologyUseCaseData, setTechnologyUseCaseData] = useState();
    const [technologyComplianceScoreChange, setTechnologyComplianceScoreChange] = useState();
    const [technologyComplianceScore, setTechnologyComplianceScore] = useState();
    const [totalDeviceCount, setTotalDeviceCount] = useState();
    const [totalDeviceCountChange, setTotalDeviceCountChange] = useState();
    let curURL = window.location.href;
    curURL = curURL.split("/");
    const techName = curURL[curURL.length-1].replaceAll("%20"," ").toLowerCase();
    const [selectedTechnology, setSelectedTechnology] = useState(techName);
    const [allTechnologies, setAllTechnologies] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [technologyComplianceTrend, setTechnologyComplianceTrend] = useState();
    const [currentUseCaseCompliance, setCurrentUseCaseCompliance] = useState();
    const [technologyName, setTechnologyName] = useState();
    const deviceIDInputRef = useRef();
    const pdfReportTechList = ["WAF-Cloudflare", "VulnerabilityManagement-Tenable", "LoadBalancer-Barracuda", "Incidents-ServiceNow", "AV-McAfee", "SecureInternetGateway-Zscaler", "Antivirus-Microsoft", "Monitoring-Solarwinds", "Panorama-PaloAltoNetworks"];

    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            let techList = data.technologyList.child;
            let allTechData = [];
            const totalActivatedTechnology = data.technologyList.totalActivatedTechnology;
            let counter = 0;
            Object.keys(techList).map((tech,index)=>{
                if(techList[tech].show){
                    ++counter;
                    allTechData.push(tech);
                    if(counter === totalActivatedTechnology)
                    {
                        setAllTechnologies(allTechData);
                    }
                }
                return 0;
            });
        });
    },[]);
    useEffect(()=>{
      // console.log(selectedTechnology);
        dataService.fetchTechnologyData(selectedTechnology,false,[],false,'',30).then(res=>{
            // console.log(res)
            setTechnologyComplianceScore(res[0].technology_compliance);
            const data = res[0];
            setTechnologyName(data['technology_name']);
            let currentUseCaseData = []
            Object.keys(data.usecase_compliance).map((usecase)=>{
                const usecaseScore = data.usecase_compliance[usecase].compliance_score;
                const usecaseData = {
                    name: usecase,
                    score: usecaseScore
                }
                currentUseCaseData.push(usecaseData);
                return 0;
            });
            setCurrentUseCaseCompliance(currentUseCaseData);
            const previousQuarterdata = res[1];
            let devices=data.usecase_compliance[Object.keys(data.usecase_compliance)[0]].device_count
            let previousQuarterDevices=previousQuarterdata.usecase_compliance[Object.keys(previousQuarterdata.usecase_compliance)[0]].device_count
            const totalDevices = devices.low+devices.medium+devices.critical;
            const totalPreviousQuarterDevices = previousQuarterDevices.low+previousQuarterDevices.medium+previousQuarterDevices.critical;
            const deviceChange = totalDevices - totalPreviousQuarterDevices;
            setTotalDeviceCountChange(deviceChange);
            setTotalDeviceCount(totalDevices);
            const scoreChange = res[0].technology_compliance - res[1].technology_compliance;
            setTechnologyComplianceScoreChange(parseFloat(scoreChange).toFixed(2));
            setTechnologyUseCaseData(data);
            res=res.filter(d=>new Date(d.time).getHours()>=0&&new Date(d.time).getHours()<6)
            setTechnologyComplianceTrend(res);
        });
    },[selectedTechnology]);
    function onTechChange(name) {
        setSelectedTechnology(name.target.value);
    }
    function showModal(state){
        setModalShow(state);
    }
    function onSearchClick(){
        setShowDeviceSerachBox(true);
    }
    function onDeviceSearchClick(){
        const deviceID = deviceIDInputRef.current.value;
        setShowDeviceSerachBox(false);
        setSystemName(deviceID);
        setShowDeviceList(true);
    }
    function generateCSV(){
        const fileName='smc_report_'+selectedTechnology.replaceAll('-','_');
        // console.log(selectedTechnology);
        csvService.technologyCSV(selectedTechnology,totalDeviceCount,fileName);
    }
    function generatePDF(){
        // console.log(technologyName);
        const fileName='smc_report_'+technologyName.replaceAll('-','_')
        dataService.technologyPDF(technologyName, fileName);
    }
    return (
        <div>
            {allTechnologies && totalDeviceCount && technologyUseCaseData && currentUseCaseCompliance?
            <Container fluid>
                <UseCaseModal techName={selectedTechnology} show={modalShow} modalShow={showModal} data={currentUseCaseCompliance}/>
                <Row style={{width:"100%", margin:"0 auto", position:"relative"}} >               
                    <Col md={6} className={classes.technologyTrendsData}>
                        <div style={{color:"#76a6ee", fontSize:"22px", display: "flex", justifyContent: "space-between", width: "95%"}}>
                            <div>
                            <select 
                                value={selectedTechnology}
                                onChange={onTechChange}
                                style={{
                                    background:"transparent",
                                    color:"#76a6ee", 
                                    border:"1px solid rgb(118, 166, 238, 0.5)",
                                }}
                            >
                                {allTechnologies.map((name, i) => {
                                    return (
                                            <option 
                                                value={name}
                                                style={{
                                                    background:"#052758", 
                                                    color:"rgba(255,255,255,0.8)",
                                                }}
                                                key={i}
                                            >
                                                {commonService.technologyKnownAs(name)}
                                            </option>
                                    )
                                })}
                            </select>
                            </div>
                            <div style={{display: 'flex', cursor:'pointer', gap:'10px'}}>
                                {pdfReportTechList.includes(technologyName)&&
                                    <div onClick={generatePDF}>
                                        <FaFilePdf size={25}/>
                                        <p style={{fontSize: '8px'}}>.pdf</p>
                                    </div>
                                }
                                <div onClick={generateCSV}>
                                    <FaFileCsv data-for='csv' data-tip='csv-report' size={25}/>
                                    <p style={{fontSize: '8px'}}>.csv</p>
                                </div>
                            </div>
                        </div>
                        <div style={{width:290, marginTop:"15px"}}>
                            <div style={{display:"inline",fontSize:"35px",fontWeight:"500"}}>
                                {technologyComplianceScore} %
                            </div>
                            {technologyComplianceScoreChange>0?
                                <div style={{float:"right", fontSize:"25px", lineHeight:"46px", color:"#05bd05"}}>
                                    +{technologyComplianceScoreChange}
                                </div>
                            :technologyComplianceScoreChange<0?
                                <div style={{float:"right", fontSize:"25px", lineHeight:"46px", color:"#EF2323"}}>
                                    {technologyComplianceScoreChange}
                                </div>
                            :<div style={{float:"right", fontSize:"25px", lineHeight:"46px", color:"#FFFFFF"}}>
                                +{technologyComplianceScoreChange}
                            </div>}
                        </div>
                        <div style={{width:290, position:"relative"}}>
                            <div 
                                style={{
                                    display:"inline",
                                    fontSize:"20px", 
                                    lineHeight:"45px"
                                }}
                            >
                                No. of Devices
                            </div>
                            <div className={classes.deviceNumber}>
                                {totalDeviceCount}
                                <FaSistrix 
                                    style={{
                                        fontSize:"20px",
                                        position:"absolute",
                                        margin:"5px 2px",
                                    }} 
                                    onClick={onSearchClick}
                                />
                            </div>
                        </div>
                            <p style={{fontSize:"18px"}}>
                                {commonService.capitalizeletterWithDash(selectedTechnology)} compliance got {parseFloat(technologyComplianceScoreChange,2)===0.00?"no improvement ":parseFloat(technologyComplianceScoreChange,2)>=0?" improved ":" deteriorated "}
                                from last quarter, with {parseFloat(technologyComplianceScoreChange,2)===0?"no changes ":parseFloat(technologyComplianceScoreChange,2)>0?("+"+technologyComplianceScoreChange+" changes"):(technologyComplianceScoreChange+" changes")} in Compliance. 
                                <br/><br/>Changes in the device volume being {totalDeviceCountChange<0?totalDeviceCountChange:"+"+totalDeviceCountChange}. 
                            </p>
                        
                    </Col>
                    <Col data-for='radar-chart' data-tip='Click me to get usecase details' md={6} className={classes.radarChart} onClick={()=>showModal(true)}>
                        <TechnologyRadarChart data={technologyUseCaseData}/>
                    </Col>
                </Row>
                <Row style={{width:"106%", height:280, marginRight:"-20", position:"relative"}} >
                    <Col md={12}>
                        <TrenologyTrendChart data={technologyComplianceTrend} name={commonService.capitalizeletterWithDash(selectedTechnology)}/>
                    </Col>
                </Row>
                <ReactTooltip id='radar-chart' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099' effect='float' />
                {showDeviceSerachBox?
                    <Modal
                        show={showDeviceSerachBox}
                        onHide={() => setShowDeviceSerachBox(false)}
                        size="s"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Search a Device
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}}>
                            <div className={classes.inputField}>
                                <input
                                type="text"
                                id="device_id"
                                size="30"
                                autoComplete="off"
                                ref={deviceIDInputRef}
                                />
                                <FaSistrix 
                                    style={{
                                        color:"white",
                                        marginLeft: "20px",
                                    }} 
                                    size={40}
                                    onClick={onDeviceSearchClick}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                :null}
                {showDeviceList && systemName?
                    <Modal
                        show={showDeviceList}
                        onHide={() => setShowDeviceList(false)}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonService.capitalizeletterWithDash(selectedTechnology)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}} className={styles.scrollbar_smc}>
                            <DeviceList 
                                techName={selectedTechnology} 
                                systemName={systemName} 
                            />
                        </Modal.Body>
                    </Modal>
                :null}
            </Container>
            :<LoadingFull/>}
        </div>
  );
}

export default TechnologyDetailsPage;