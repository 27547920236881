import React, { useState, useMemo, useEffect } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table'
import { Loading } from '@CommonComponents/Loading/Loading';
import { dataService } from '@Services';
import styles from '@Utilities/scrollbar.module.css';
import classes from './CveTable.module.css';
import { FaAngleRight, FaAngleLeft, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

function Table({ columns, data }) {
    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    return (
        <div>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <table className={classes.technologies} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr style={{cursor:'pointer'}} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted? 
                                            column.isSortedDesc? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                    </span>
                                </th>
                            ))}
                            <th>Assessment Details</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                                <td>
                                    <a href={"https://nvd.nist.gov/vuln/detail/"+row.cells[0].value} target="_blank">Link</a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className={classes.inputField}>
                <div>
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={!canPreviousPage} 
                        size='small' 
                        onClick={() => gotoPage(0)} 
                        className={classes.navButtons}
                    >
                        <FaAngleDoubleLeft />
                    </Button>
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={!canPreviousPage} 
                        size='small' 
                        onClick={() => previousPage()} 
                        className={classes.navButtons}
                    >
                        <FaAngleLeft />
                    </Button>
                    {' '}Showing Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={!canNextPage} 
                        size='small' 
                        onClick={() => nextPage()} 
                        className={classes.navButtons}
                    >
                        <FaAngleRight />
                    </Button>
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={!canNextPage} 
                        size='small' 
                        onClick={() => gotoPage(pageCount - 1)} 
                        className={classes.navButtons}
                    >
                        <FaAngleDoubleRight />
                    </Button>
                </div>
                <div>
                    Go To 
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '30px', height: '20px' }}
                    />{' Page  '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{
                            background:"transparent",
                            color:"#76a6ee", 
                            border:"1px solid rgb(118, 166, 238, 0.5)",
                            width: '120px', 
                            height: '38px'
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option 
                            key={pageSize} 
                            value={pageSize} 
                            style={{
                                background:"#052758", 
                                color:"rgba(255,255,255,0.8)",
                            }}
                            >
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

function CVEPage() {
    const [data,setData]=useState();
    useEffect(() => {
        dataService.fetchKnownVulnerabilities().then((res)=>{
            setData(res.data['vulnerabilities'])
        })
    },[])
    const columns = useMemo(
        () => [
            {
                        Header: 'CVE ID',
                        accessor: 'cveID',
                    },
                    {
                        Header: 'Vendor',
                        accessor: 'vendorProject'
                    },
                    {
                        Header: 'Product',
                        accessor: 'product'
                    },
                    {
                        Header: 'Vulnerability Name',
                        accessor: 'vulnerabilityName'
                    },
                    {
                        Header: 'Short Description',
                        accessor: 'shortDescription'
                    },
                    {
                        Header: 'Required Action',
                        accessor: 'requiredAction'
                    },
                    {
                        Header: 'Notes',
                        accessor: 'notes'
                    },
                    {
                        Header: 'Date Added',
                        accessor: 'dateAdded'
                    },
                    {
                        Header: 'Due Date',
                        accessor: 'dueDate'
                    },
        ],
        []
    )
    
    return (
        <React.Fragment>
        {data?
        <div className={styles.scrollbar_smc}>
            <Table columns={columns} data={data} />
        </div>
        :<Loading />}
        </React.Fragment>
    )
}

export default CVEPage;