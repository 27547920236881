import ReactECharts from 'echarts-for-react';
// import { commonService } from '@Services';
// import { color } from 'echarts';

function SeverityChart(props) {

    const name = props.name ?? "ANC"
    let data = null
    let colors = []
    
    if(props.data)
    {   
        let critical= 0, high=0, low=0, medium=0, unknown=0;
        props.data.map(item=>{
            if(item.severity === 'Critical'){
                critical = item.device_count
            }
            else if(item.severity === 'High'){
                high = item.device_count
            }
            else if(item.severity === 'Medium'){
                medium = item.device_count
            }
            else if(item.severity === 'Low'){
                low = item.device_count
            }
            else
                unknown = item.device_count
        })
        let tempData = [
            {'severity': 'Critical', 'device_count':critical},
            {'severity': 'High', 'device_count':high},
            {'severity': 'Medium', 'device_count':medium},
            {'severity': 'Low', 'device_count':low},
            {'severity': 'Unknown', 'device_count':unknown}
        ]
        const propsData = tempData;
        const propName = props.name;
        let arrangedData = [];
        for (const [index, value] of propsData.entries()) {
            const chartDatam = {
                name: String(value[propName]),
                value: value.device_count
            }
            arrangedData.push(chartDatam);
        }
        data = arrangedData;
    }
    

    function handleChartClick(params){
        // console.log(params);
          const filterBy = props.name;
          const filterValue = params.data.name;
          console.log(params.data)
          const totalCount = params.data.value;
          const filter = filterBy+'='+filterValue
          props.handleClick(filter, totalCount);
      }

    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            
                type: 'shadow'        
            },
            formatter: function (params) {
                return `${name}<br />
                        <div style="background-color:${params[0].color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params[0].name}: ${params[0].data.value}<br />`;  
            },
            textStyle: {
                color : '#000',
                fontWeight: '600',
                fontSize: '1.2em'
            }
        },
        legend: {
            
        },
        color: colors,
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: data.map((entry)=>{
                    return entry.name
                }),
                axisTick: {
                    alignWithLabel: false
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: '.8em'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    color: '#fff',
                    fontSize: '.8em'
                }
            }

        ],
        series: [
            {
                name: '',
                type: 'bar',
                barWidth: '60%',
                data: data,
                itemStyle: {
                    color: function (param) {
                        // console.log(param);
                        return props.color[param.dataIndex];
                    }
                }
            }
        ]
    };

    return (
        <div>
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height:"290px"
                }} 
                onEvents={{'click':handleChartClick}}
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:"1em",
                    fontWeight: 700
            }}>{name}</p>
        </div>
    )
}



export default SeverityChart