import React,{useEffect, useState} from 'react';
import {Loading} from '@CommonComponents/Loading/Loading';
import SeverityChart from './SeverityChart/SeverityChart';
import { annotateService, commonService } from '@Services';
import classes from './IncidentsCybershield.module.css';
import Modal from 'react-bootstrap/Modal';
import DeviceList from '@CommonComponents/DeviceList/DeviceList';
import IncidentLineChart from './IncidentLineChart/IncidentLineChart';
import FunnelChart from '../../../../../../common-components/ECharts/FunnelChart/FunnelChart';
import PieRoseTypeChart from '../../../../../../common-components/ECharts/PieChart/PieRoseTypeChart';
import DoughnutChart from '../../../../../../common-components/ECharts/DoughnutChart/DoughnutChart';

function IncidentsCybershield(props){
    const [techAnnotationData, setTechAnnotationData] = useState();
    const [openDeviceList, setOpenDeviceList] = useState(false);
    const [paramFilter, setParamFilter] = useState();
    const [totalDeviceCount, setTotalDeviceCount] = useState(0);
    const technologyName = 'incidents-cybershield'


    useEffect(()=>{
        annotateService.getTechStartDate(technologyName).then((startDate)=>{
            annotateService.annotate(technologyName,startDate).then((res)=>{
                // const result = res.filter(r => r !== "time");
                // console.log(result);
                let annotateData = []
                Object.keys(res).map((columnName)=>{
                    if(columnName !== "time")
                    {
                        const data={
                            name: columnName,
                            data: res[columnName]
                        }
                        annotateData.push(data);
                    }
                });
                setTechAnnotationData(annotateData);
            });
        });
    },[]);

    function handleChartClick(filter, totalCount){
        // console.log(filter);
          setParamFilter(filter);
          setTotalDeviceCount(totalCount);
          setOpenDeviceList(true);
    }
    
    
    return (
    <div>
            {techAnnotationData?
            <div className={classes.gridContainer}>
                {techAnnotationData.map( (tech,i) => (
                    <div className={classes.gridItem} key={i}>
                        <div className={classes.customCard}>
                        {
                            (tech.name === 'incident_type')?
                                <FunnelChart center={['50%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data} />
                            :(tech.name === 'incident_status')?
                                <PieRoseTypeChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data} colors={['yellow', 'red']}/>
                            :(tech.name === 'incident_SLA')?
                                <DoughnutChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data} colors={['red', 'gray']}/>
                            :(tech.name === 'severity')?
                                <SeverityChart center={['50%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data} color={['#ff471a', '#f6961e', '#ecdb23', '#6ad72d', 'gray']}/>
                            :(tech.name === 'owner')?
                                <PieRoseTypeChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data}/>
                            :
                            <PieRoseTypeChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={tech.name} data={tech.data}/>
                        }
                        </div>
                    </div>
                ))}
                <div className={classes.gridItem}>
                    <div className={classes.customCard}>
                        <IncidentLineChart center={['50%','50%']} handleClick={handleChartClick} techName={technologyName} name="incident_status"/>
                    </div>
                </div>
            </div>
        :<Loading/>}
        {openDeviceList?
            <Modal
                show={openDeviceList}
                onHide={() => setOpenDeviceList(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {commonService.capitalizeletterWithDash(technologyName)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}}>
                    <DeviceList techName={technologyName} params={paramFilter} totalCount={totalDeviceCount}/>
                </Modal.Body>
            </Modal>
        :null}
    </div>
    );
}

export default IncidentsCybershield