import React,{useState,useEffect} from "react";
import {annotateService,deviceService,commonService} from '@Services';
import Card from '@CommonComponents/Card/Card';
import {Container, Row, Col} from 'react-bootstrap';
import FunnelChart from '@CommonComponents/ECharts/FunnelChart/FunnelChart';
import classes from '../../../../../../common-components/DeviceList/DeviceList.module.css';
import InsightTechnology from '../../../InsightTechnology'
import Modal from 'react-bootstrap/Modal';
import DeviceList from '@CommonComponents/DeviceList/DeviceList';
import Chart from "react-apexcharts";
import InsightsCard from '@CommonComponents/Card/InsightsCard';

function BPSG(){
    const technologyName = 'bluecoatproxysg-symantec';
    const [radialchartData, setRadialChartData] = useState();
    const [platformChartData, setPlatformChartData] = useState();
    const [deviceCount, setDeviceCount] = useState();
    const [openDeviceList, setOpenDeviceList] = useState(false);
    const [paramFilter, setParamFilter] = useState();
    const [totalDeviceCount, setTotalDeviceCount] = useState(0);

    useEffect(()=>{
        annotateService.getTechStartDate(technologyName).then((startDate)=>{
            annotateService.annotate(technologyName,startDate).then((res)=>{
            //    console.log(res)
               let l = []
               let d = {}
               if (res){
                    Object.keys(res).map((usecase,i)=>{
                        if(usecase === 'device_health'){
                            // setRadialChartData(res[usecase]['Critical'],res[usecase]['OK'],res[usecase]['Warning'])
                            res[usecase].map((data,j)=>{
                                if (data['device_health']==='Critical'){
                                    l.push(data['device_count'])
                                } else if (data['device_health']==='OK'){
                                    l.push(data['device_count'])
                                } else if (data['device_health']==='Warning'){
                                    l.push(data['device_count'])
                                }
                            })
                        } else if (usecase === 'platform_version'){
                            d={
                                name: usecase,
                                data: res[usecase]
                            }
                        }
                    })
                    setDeviceCount(l[0] + l[1] + l[2])
                    setRadialChartData(l)
                    setPlatformChartData(d)
                }
            });
        });
    },[])
    const options = {
        chart: {
            height: 350,
            type: 'radialBar',
            events: {
                dataPointSelection: function(event, chartContext, config) {
                  // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    let severity = config.w.config.labels[config.dataPointIndex]
                    let count = config.w.config.series[config.dataPointIndex]
                    count = Math.round((12*count)/100)
                    let param = 'device_health='+severity
                    setParamFilter(param)
                    setTotalDeviceCount(count)
                    setOpenDeviceList(true);
                }
            }          
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '20px',
                  color:'white'
                },
                value: {
                  fontSize: '16px',
                  color:'white'
                },
                total: {
                  show: true,
                  label: 'Total',
                  color:'white',
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return 12;
                  }
                }
              },
              offsetX: -100,
            }
        },
        labels: ['Critical', 'OK', 'Warning'],
        colors: ['#FF0000', '#008000', '#FFBF00'],
        legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'right',
            borderColor:'white',
            offsetX: 80,
            offsetY: 15,
            labels: {
              useSeriesColors: true,
            }
        }
    }
    let series = []
    if(radialchartData){
        series = [
            (Math.round((radialchartData[0]/deviceCount*100),2)),
            (Math.round((radialchartData[1]/deviceCount*100),2)),
            (Math.round((radialchartData[2]/deviceCount*100),2))
        ]
    }
    
    function handleChartClick(filter, totalCount){
        // console.log(filter);
          setParamFilter(filter);
          setTotalDeviceCount(totalCount);
          setOpenDeviceList(true);
    }

    return(
        <React.Fragment>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:'0px',marginBottom:"15px",marginRight:'5px'}} >
                        {/* <RadialChart id="#Chart" data={radialchartData}/> */}
                    <Col lg={6} key={'radial_chart'}>
                        <InsightsCard>
                            <Chart
                                options={options}
                                series={series}
                                type="radialBar"
                                height={300}
                                center={['50%','50%']}
                            />
                            <p
                                style={{
                                textAlign: "center",
                                fontSize: "1em",
                                fontWeight: 700,
                                }}
                            >
                                Device Health
                            </p>
                        </InsightsCard>
                    </Col>
                    <Col lg={6} key={'usecase_chart'}>
                        <InsightsCard>
                            {platformChartData?
                                <FunnelChart center={['50%','50%']} handleClick={handleChartClick} techName={technologyName} name={platformChartData.name} data={platformChartData.data} colors={['#F2921D']}/>
                            :null}
                            </InsightsCard>
                    </Col>
                </Row>
                <InsightTechnology techName={technologyName}/>
                {openDeviceList?
                    <Modal
                        show={openDeviceList}
                        onHide={() => setOpenDeviceList(false)}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={true}
                        >
                        <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonService.capitalizeletterWithDash(technologyName)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}}>
                            <DeviceList techName={technologyName} params={paramFilter} totalCount={totalDeviceCount}/>
                        </Modal.Body>
                    </Modal>
                :null}
            </Container>
        </React.Fragment>
    )
}

export default BPSG;