import React from 'react';  
import $ from 'jquery';
import { commonService } from '@Services';
import {FaAngleRight} from 'react-icons/fa';

function makeID(name) {
    // console.log(name.split(' ').map(i=>i?i[0].toLowerCase()+i.slice(1):'').join('_'))
    return name.split(' ').map(i=>i?i[0].toLowerCase()+i.slice(1):'').join('_')
}

function Details(props) {
    // console.log(props);
    let processData = ''
    if (typeof(props.data)==='object' && props.data){
        processData = Object.keys(props.data).map((d, i) => {
            // console.log(d);
            return (
                <Details 
                    property={d} 
                    data={props.data[d]} 
                    tabbing={props.tabbing+1} 
                    key={i} 
                    // parent={d==='data' ? props.property : null}
                    parent={props.property}
                />
            )
        })
    }

    return (
        <div style={{color: 'black'}}>
            <div onClick={()=>{
                // console.log(props)
                $("."+(props.parent ? props.parent+'_' : '')+makeID(props.property)+"_child").toggle(()=>{
                    if ($("."+(props.parent ? props.parent+'_' : '')+makeID(props.property)+"_child").is(":hidden")){
                        $(".toggle_"+(props.parent ? props.parent+'_' : '')+makeID(props.property)+" svg").css({'transform' : 'rotate('+ 0 +'deg)'});
                    } else {
                        $(".toggle_"+(props.parent ? props.parent+'_' : '')+makeID(props.property)+" svg").css({'transform' : 'rotate('+ 90 +'deg)'});
                    }
                })
            }}
            style={{
                display:"inline"
            }}
            >
                <span className={"boxDrawingChar"}>
                    {props.tabbing>0? '└─':''}{'─'.repeat(props.tabbing)} 
                </span> 
                {typeof(props.data)==='object' && props.data ? 
                    <b style={{cursor:"pointer",color:Object.keys(props.data).length===0?"#6b6b6b":"inherit"}}>
                        { commonService.capitalizeletterWith_(props.property) }
                        {Object.keys(props.data).length>0 ? <span className={"toggle_"+(props.parent ? props.parent+'_' : '')+makeID(props.property)}> <FaAngleRight /></span> :""}
                    </b>
                :<b>{commonService.capitalizeletterWith_(props.property)}</b>}
            </div> 
            {typeof(props.data)==='object' && props.data ? 
                <div className={(props.parent ? props.parent+'_' : '')+makeID(props.property)+"_child"} style={{marginLeft:"10px",display:"none"}}>
                    {processData}
                </div> 
            :": " +props.data}
        </div>
    )
}
export default Details