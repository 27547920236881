import ReactECharts from "echarts-for-react";
import { commonService } from "@Services";

function FunnelChart(props) {
  const name = props.name
    ? commonService.capitalizeletterWith_(props.name)
    : "ANC";
  let data = null;
  let colors = props.colors? props.colors: [];
  const dummyData = [
    { value: 60, name: "A" },
    { value: 40, name: "B" },
    { value: 20, name: "C" },
    { value: 80, name: "D" },
    { value: 100, name: "E" },
  ];
  if (props.data) {
    const propsData = props.data;
    const propName = props.name;
    let arrangedData = [];
    for (const [index, value] of propsData.entries()) {
      const chartDatam = {
        name: String(value[propName]),
        value: value.device_count,
      };
      arrangedData.push(chartDatam);
    }
    data = arrangedData;
  }

  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${name}<br />
                        <div style="background-color:${params.color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params.name}: ${params.data.value} (${params.percent}%)<br />`;
      },
      textStyle: {
        color: "#000",
        fontWeight: "600",
        fontSize: "1.2em",
      },
    },
    legend: {
      type: "scroll",
      top: "10%",
      width: '40%',
      pageIconInactiveColor: "#475067",
      pageTextStyle: {
        color: "#abc",
        fontWeight: 500,
        fontSize: 10,
      },
      left: "55%",
      orient: "vertical",
      textStyle: {
        color: "#fff",
        fontSize: 11,
        fontWeight: 400,
        width: '200',
        overflow: 'truncate'
      },
      borderColor: "#fff",
      borderWidth: 1,
      borderRadius: 10,
      padding: 10,
    },
    series: [
      {
        name: name,
        type: "funnel",
        left: "5%",
        top: '10%',
        //x2: 80,
        bottom: 10,
        width: "45%",
        height: '80%',
        color: [...colors, '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        // height: {totalHeight} - y - y2,
        sort: "descending",
        gap: 2,
        label: {
          show: true,
          position: "inside",
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        itemStyle: {
          borderColor: "#222",
          borderWidth: 0,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "20",
            fontWeight: "bold",
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: data ?? dummyData,
      },
    ],
  };
  function handleChartClick(params) {
    // console.log(params);
    const filterBy = props.name;
    const filterValue = params.data.name;
    const totalCount = params.data.value;
    const filter = filterBy + "=" + filterValue;
    props.handleClick(filter, totalCount);
  }
  return (
    <div>
      <ReactECharts
        option={options}
        style={props.style}
        onEvents={{ click: handleChartClick }}
      />
      <p
        style={{
          textAlign: "center",
          fontSize: "1em",
          fontWeight: 700,
        }}
      >
        {name}
      </p>
    </div>
  );
}

export default FunnelChart;
