import AWSPage from "./components/AWSPage/AWSPage";
import Incident from "./components/Incidents/Incidents"
import IncidentsCybershield from "./components/IncidentsCybershield/IncidentsCybershield"
import SkyHigh from "./components/SkyHigh/SkyHigh";
import BPSG from "./components/BPSG/BPSG";

function CustomizableTechnologyInsightPage(props){
    const technologyName = props.techName ?? '';
  // console.log(technologyName);
    return (
        <div>
            {technologyName==='aws'?
                <AWSPage/>
            :null}
            {technologyName==='incident'?
                <Incident/>
            :null}
            {technologyName==='incidents-cybershield'?
                <IncidentsCybershield/>
            :null}
            {technologyName==='webgateway-skyhighsecurity'?
                <SkyHigh />
            :null}
            {technologyName==='bluecoatproxysg-symantec'?
                <BPSG />
            :null}
        </div>
    );
}

export default CustomizableTechnologyInsightPage;