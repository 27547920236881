export const smcUtility={
    smcSetting
}

function smcSetting(){
    return Promise.resolve(setting);
}

let setting ={
    end_of_life:{
        show:true
    },
    incident:{
        show:true
    },
    automation:{
        show:true
    },
    topUsecaseList:{
        's3-aws':['policy'],
        'firewall-skyboxsecurity':['unused_rules','rule_violation_count'],
        'av-mcafee':['managed_state'],
        // 'vulnerabilitymanagement-tenable':['static_severity'],
    },
    insight:{
        show:true,
        child:{
            endpoint_security:{
                show:true,
                child:{
                    // 'antivirus-microsoft':{auto:true},
                    'av-mcafee':{auto:true},
                }
            },
            network_security:{
                show:true,
                child:{
                    // 'antivirus-microsoft':{auto:true},
                    'webgateway-skyhighsecurity':{auto:false},
                    'bluecoatproxysg-symantec':{auto:false}
                }
            },
            kpi_dashboard:{
                show:false,
                child:{
                }
            },
            cloud_security:{
                show:true,
                child:{
                    'aws':{auto:false},
                }
            },
            data_security:{
                show:false,
                child:{
                }
            },
            monitoring:{
                show:true,
                child:{
                    'monitoring-solarwinds':{auto:true},
                    'incidents-cybershield':{auto:false}
                }
            },
            ticketing:{
                show:true,
                child:{
                    // 'ticketing-servicenow':{auto:true},
                    'incident':{auto:false}
                }
            },
            executive_summary:{
                show:false,
                child:{
                }
            },
            vulnerability_management:{
                show:false,
                child:{
                    'vulnerabilitymanagement-tenable':{auto:true}
                }
            }
        },
        customizableChild:{
            automation:{show:true},
            sma:{show:true},
            remote_access:{show:true},
            compliance_monitoring:{show:true},
        }
    },
    technologyList:{
        totalActivatedTechnology: 16,
        child:{
            // 'hips-mcafee':{show:true},
            'av-mcafee':{show:true},
            // 'antivirus-symantec':{show:false},
            // 'dlpdiscover-symantec':{show:false},
            // 'dlpendpoint-symantec':{show:false},
            'firewallanalyzer-firemon':{show:true},
            // 'dlp-symantec':{show:false},
            // 'loadbalancer-f5':{show:false},
            's3-aws':{show:true},
            'ec2-aws':{show:true},
            'monitoring-solarwinds':{show:true},
            'cmdb-servicenow':{show:true},
            'incidents-servicenow':{show:true},
            // 'people-people':{show:false},
            // 'process-process':{show:false},
            'panorama-paloaltonetworks':{show:true},
            // 'bluecoatproxy-symantec':{show:false},
            // 'firewallmanagement-checkpoint':{show:false},
            'secureinternetgateway-zscaler':{show:true},
            // 'vpn-pulsesecure':{show:false},
            // 'dlp-mcafee':{show:trye},
            // 'bitlockerencryption-microsoft':{show:false},
            'firewall-skyboxsecurity':{show:true},
            // 'umbrella-cisco':{show:false},
            // 'centralmanagement-fireeye':{show:false},
            // 'nsm-mcafee':{show:false},
            'antivirus-microsoft':{show:true},
            // 'firewallanalyzer-algosec':{show:false},
            // 'hardening-cis':{show:false},
            // 'patching-microsoft':{show:false},
            // 'securitycenterdevices-tenable':{show:false},
            'loadbalancer-barracuda':{show:false},
            'vulnerabilitymanagement-tenable':{show:true},
            'waf-cloudflare':{show:true},
            'bluecoatproxysg-symantec':{show:true},
            'webgateway-skyhighsecurity':{show:true},
            'dlp-symantec':{show:true}
        }
    },
}