import { userService } from '@Services';
import { urlConstants } from '@Constants';
import { logger } from '@Utilities';
import Api from '@Api/SMCApi';

export const taskService = {
    createTask,
    fetchTask
};

function createTask(data){
    // console.log(data)
    const payload = JSON.stringify(data)
    // console.log(payload)
    let url = urlConstants.SITE_URL + urlConstants.CREATE_TASK

    const token=userService.getToken();
    // console.log(url);
    return Api.post(url,data,{headers: {Authorization : `Bearer ${token}`}}).then(response => {
        // console.log(response)
        return response;
    }).catch(err =>{
      if(String(err).includes('500'))
      {
          logger.debug(err);
      }
      else
          logger.debug(err);
    });
}

function fetchTask(startDate,endDate){
  
    // let url = urlConstants.SITE_URL + urlConstants.FETCH_TASK + '?' + urlConstants.FILTER_START_DATE + '=' + startDate + 
    //           '&' + urlConstants.FILTER_END_DATE + '=' + endDate;
    let url = urlConstants.SITE_URL + urlConstants.FETCH_TASK 

    const token=userService.getToken();

    
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(response => {
        // console.log(response)
        return response;
    }).catch(err =>{
      if(String(err).includes('500'))
      {
          logger.debug(err);
      }
      else
          logger.debug(err);
    });
}