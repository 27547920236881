import React,{useEffect,useState,useRef} from "react";
import { taskService,commonService,csvService } from "@Services";
import { FaSistrix,FaFileDownload } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
// import { annotateService,csvService,deviceService,commonService } from '@Services';
import styles from '@Utilities/scrollbar.module.css';
import classes from '../../../../../../common-components/DeviceList/DeviceList.module.css';
// import classes from '../../../../../../../common-components/DeviceList/DeviceList.module.css';
import classess from '../../../../../TechnologyDetailsPage/TechnologyDetailsPage.module.css';
// import classess from '../../../../../../TechnologyDetailsPage/TechnologyDetailsPage.module.css';
import Button from 'react-bootstrap/Button';
import { Loading } from '@CommonComponents/Loading/Loading';

function Incident(){
    
    const now = new Date();
    let SD=commonService.getNowStartDate().split(' ')[0] + ' 00:00:00';
    let ED=commonService.getNowStartDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30))
    // console.log(ED)
    const [data,setData]=useState();
    const [usercount,setUserCount]=useState();
    const [tabledata, setTableData]=useState();
    const [offSet, setOffSet] = useState(0);
    const [pageLength,setPageLength] = useState(0);
    const [showDeviceSerachBox, setShowDeviceSerachBox] = useState(false);
    const [filter, setFilter] = useState(false)
    const [csvData, setcsvData] = useState();

    const deviceIDInputRef = useRef();

    function handleSearch(){
        setOffSet(0)
        setFilter(false)
     }
 
     function onSearchClick(){
         setShowDeviceSerachBox(true);
     }
 
     function onDeviceSearchClick(){
         const deviceID = deviceIDInputRef.current.value;
         setShowDeviceSerachBox(false);
         setFilter(deviceID);
     }
 
    function handleNext() {
         const os = parseInt(offSet) + pageLength;
         setOffSet(os);
     }
     function handlePrev() {
         const os = parseInt(offSet) - pageLength;
         if(parseInt(os)<0)
             setOffSet(0);
         else
             setOffSet(os);
     }
 
     function downloadCSV(){
         console.log('Clicked on Download');
         const fileName = "smc_incident_report";
         if(csvData) {
             csvService.makeCsv(csvData,fileName);
         }
     }

     useEffect(()=>{
        taskService.fetchTask(SD,ED).then((res,j)=>{
            // console.log(res)
            res.data.map((incident,j)=>{
                Object.keys(incident).map((key,k)=>{
                    if(key==='time'){
                        let t=incident[key].split('T')[0] + ' ' + incident[key].split('T')[1].replaceAll('Z','')
                        incident[key]=t.substring(0,(t.indexOf('.')))
                    }
                    return 0;
                })
                return 0;
            })
            // console.log(res.data)
            setData(res.data)
        })
     },[SD, ED])
 
     useEffect(()=>{
         let tableD=[]
         let filterdata=[]
         if(data)
         {  
             if(filter){
                 data.map((obj,i)=>{
                     if(Object.values(obj).indexOf(filter)>-1)
                     {
                         filterdata.push(obj)
                     }
                     return 0;
                 })
                 if(filterdata.length>10)
                 {
                     for (let i=0;i<10;i++)
                     {   if((offSet+i)<filterdata.length)
                             tableD.push(filterdata[offSet + i])
                     }
                     setPageLength(tableD.length)
                     setTableData(tableD)
                     setUserCount(filterdata.length)
                     setcsvData(filterdata)
                 }
                 else if(filterdata.length>0&&filterdata.length<=10){
                     setPageLength(filterdata.length)
                     setTableData(filterdata)
                     setcsvData(filterdata)
                 }
                 else {
                     const objj={
                         'system_name':'No Data Available',
                         'Username': 'No Data Available',
                         'Assigned_IP':'No Data Available',
                         'Public_IP':'No Data Available',
                         'Group_Policy':'No Data Available',
                         'Tunnel_Group':'No Data Available',
                         'Login_Time':'No Data Available',
                         'Duration':'No Data Available',
                         'Inactivity':'No Data Available',
                         'Client_OS':'No Data Available',
                         'Client_OS_Ver':'No Data Available',
                         'Client_Type':'No Data Available',
                         'Client_Ver':'No Data Available'
                     }
                     filterdata.push(objj)
                     setUserCount(0)
                     setTableData(filterdata)
                     setcsvData(filterdata)
                 }
             }
             else
             {
                 for (let j=0;j<10;j++)
                 {   if((offSet+j)<data.length)
                         tableD.push(data[offSet + j])
                 }
                 // console.log(tableD)
                 setPageLength(tableD.length)
                 setUserCount(data.length)
                 setTableData(tableD)
                 setcsvData(data)
             }
         }
     },[offSet,data,filter])

    return(
        <div>
        <div className={styles.scrollbar_smc}>
            {tabledata?
                <React.Fragment>
                    <table className={classes.technologies} > 
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Incident Number</th>
                                <th>System Name</th>
                                <th>IP Address</th>
                                <th>Technology</th>
                                <th>Usecase</th>
                                <th>Sys ID</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabledata.map((data, i) => (
                                <tr key={i}>
                                    <React.Fragment>
                                        {data.hasOwnProperty('time')?
                                            <td>
                                                {data['time']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('incident_number')?
                                            <td>
                                                {data['incident_number']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('hostname')?
                                            <td>
                                                {data['hostname']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('ip_address')?
                                            <td>
                                                {data['ip_address']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('technology')?
                                            <td>
                                                {data['technology']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('usecase')?
                                            <td>
                                                {data['usecase']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('sys_id')?
                                            <td>
                                                {data['sys_id']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('short_description')?
                                            <td>
                                                {data['short_description']}
                                            </td>
                                        :null}                
                                    </React.Fragment>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={classes.tableFooter}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                variant='secondary' 
                                color='primary' 
                                disabled={offSet<=0?true:false} 
                                size='small' 
                                onClick={handlePrev} 
                                className={classes.navButtons}
                            >
                                Prev
                            </Button>
                            <div 
                                style={{
                                    color:'white',
                                    padding:'0 10px'
                                }}
                            >
                                Showing {offSet+1}-{offSet+pageLength} of {usercount}
                            </div>
                            <Button 
                                variant='secondary' 
                                color='primary' 
                                disabled={(offSet+pageLength)>=usercount?true:false} 
                                size='small' 
                                onClick={handleNext}
                                className={classes.navButtons}
                            >
                                Next
                            </Button>
                            <div style={{
                                    color:'white',
                                    padding:'0 10px',
                                    cursor:'pointer'
                                }}>
                                <FaFileDownload onClick={downloadCSV} size={25} />
                            </div>
                            <div className={classess.devieNumber}>
                                <FaSistrix 
                                    style={{
                                        color:'white',
                                        marginLeft:'10px',
                                        fontSize:"20px",
                                        cursor:"pointer"
                                    }}
                                    onClick={onSearchClick}
                                />
                            </div>
                            {filter?
                                <Button 
                                    variant='secondary' 
                                    color='primary' 
                                    disabled={filter?false:true} 
                                    size='small' 
                                    onClick={handleSearch}
                                    // className={classes.navButtons}
                                    style={{
                                        marginLeft:'765px',
                                        border: '1px solid #000 !important',
                                        boxShadow: '1px 1px 1px #000'
                                    }}
                                >
                                    Exit Search
                                </Button>
                            :null}
                        </div>
                    </div>
                    {showDeviceSerachBox?
                    <Modal
                        show={showDeviceSerachBox}
                        onHide={() => setShowDeviceSerachBox(false)}
                        size="s"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Search a Device
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}}>
                            <div className={classess.inputField}>
                                <input
                                type="text"
                                id="device_id"
                                size="30"
                                autoComplete="off"
                                ref={deviceIDInputRef}
                                />
                                <FaSistrix 
                                    style={{
                                        color:"white",
                                        marginLeft: "20px",
                                    }} 
                                    size={40}
                                    onClick={onDeviceSearchClick}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                    :null}
                </React.Fragment>   
            :<Loading />}
        </div>
    </div>
    )
}

export default Incident;


