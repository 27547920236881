import React,{useEffect, useState} from 'react';
import {Loading} from '@CommonComponents/Loading/Loading';
import FunnelChart from '@CommonComponents/ECharts/FunnelChart/FunnelChart';
import PieRoseTypeChart from '@CommonComponents/ECharts/PieChart/PieRoseTypeChart';
// import PieChart from '@CommonComponents/ECharts/PieChart/PieChart';
import DoughnutChart from '@CommonComponents/ECharts/DoughnutChart/DoughnutChart';
import BarChart from '@CommonComponents/ECharts/BarChart/BarChart';
import InsightsCard from '@CommonComponents/Card/InsightsCard';
import { annotateService, commonService } from '@Services';
import Modal from 'react-bootstrap/Modal';
import DeviceList from '@CommonComponents/DeviceList/DeviceList';
import { Container, Row, Col } from 'react-bootstrap';


function InsightTechnology(props){
    const [techAnnotationData, setTechAnnotationData] = useState();
    const [openDeviceList, setOpenDeviceList] = useState(false);
    const [paramFilter, setParamFilter] = useState();
    const [totalDeviceCount, setTotalDeviceCount] = useState(0);
    const technologyName = props.techName;

    useEffect(()=>{
        annotateService.getTechStartDate(technologyName).then((startDate)=>{
            annotateService.annotate(technologyName,startDate).then((res)=>{
                let annotateData = []
                Object.keys(res).map((columnName,index)=>{
                    if(columnName !== "time" && columnName !== "health_check" && columnName !== "platform_version" && columnName !== "web_traffic" && columnName != "device_health")
                    {
                        const data={
                            name: columnName,
                            data: res[columnName]
                        }
                        annotateData.push(data);
                    }
                    return 0;
                });
                let techData = []
                const chunkSize = 4;
                for (let i = 0; i < annotateData.length; i += chunkSize) {
                    const chunk = annotateData.slice(i, i + chunkSize);
                    techData.push(chunk)
                }
                setTechAnnotationData(techData);
            });
        });
    },[technologyName]);

    function handleChartClick(filter, totalCount){
      // console.log(filter);
        setParamFilter(filter);
        setTotalDeviceCount(totalCount);
        setOpenDeviceList(true);
    }

    return (
        <div>
             {techAnnotationData?
                <Container fluid>
                    {techAnnotationData.map( (tech,i) => (
                        <Row key={i} style={{ width: "100%"}}>
                        {tech.map((usecase,ind) => (
                            <Col lg={6} key={ind}>
                                <InsightsCard>
                                    {ind%4===0?<FunnelChart center={['50%','50%']} handleClick={handleChartClick} techName={technologyName} name={usecase.name} data={usecase.data} colors={['#F2921D']}/>
                                    :ind%4===1?<PieRoseTypeChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={usecase.name} data={usecase.data} colors={['#AA96DA']}/>
                                    :ind%4===2?<DoughnutChart center={['25%','50%']} handleClick={handleChartClick} techName={technologyName} name={usecase.name} data={usecase.data} colors={['#FCBAD3']}/>
                                    :<BarChart center={['70%','50%']} handleClick={handleChartClick} techName={technologyName} name={usecase.name} data={usecase.data} />}
                                </InsightsCard>
                            </Col>))
                        }
                        </Row>
                    ))}
                </Container>
            :<Loading/>}
            {openDeviceList?
                <Modal
                    show={openDeviceList}
                    onHide={() => setOpenDeviceList(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                    
                    >
                    <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {commonService.capitalizeletterWithDash(technologyName)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body  style={{backgroundColor: "rgb(40,41,42)"}}>
                        <DeviceList techName={technologyName} params={paramFilter} totalCount={totalDeviceCount}/>
                    </Modal.Body>
                </Modal>
            :null}
        </div>
  );
}
export default InsightTechnology;