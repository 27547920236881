import { urlConstants } from '@Constants';
import {userService, commonService} from '@Services'
import Api from '@Api/SMCApi';
import { logger } from '@Utilities';
import axios from 'axios';

export const dataService = {
    fetchDataWithDate,
    fetchSubCategoryData,
    fetchTechnologyData,
    getTechStartDate,
    latestTechCompliance,
    fetchWeeklyCategoryData,
    fetchKnownVulnerabilities,
    howManYLatestTechCompliance,
    getExecutiveSummary,
    technologyPDF
};

function logout(){
  let url = window.location.href;
  url = url.split('/')[0];
  if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}

let dateChanged='';
function classifyCount(tech){
  tech=tech.toLowerCase()
  if(tech==='cmdb-servicenow') return 'object_count'
  if(tech==='ticketing-servicenow') return "engineer's_count"
  return 'device_count'
}
function filterUsecasesUnderTechnologyUnderSubCategory(technology,useCaseList,data){
    // console.log(technology,useCaseList,data)
    let useCases=[]
    useCaseList.map(usecase=>{
       useCases.push({name:usecase,score:data[usecase].compliance_score,count:data[usecase][classifyCount(technology)]})
       return 0;
    })
    data={}
    data[technology]=useCases
    return {technology:technology,data:useCases}
}
  
function makeCategoryData(props){
  // logger.debug(props)
    // commonService.storeDate('category',props[0].time)
    let prevData,currData;
    if(dateChanged) console.log('Date Changed To:', dateChanged)
    if(props.length<2){
      // return fetchDataWithDate();
      prevData=props[0];currData=props[0]
    }
    else if(new Date(props[0].time)>new Date(props[1].time)){
      prevData=props[1];currData=props[0]
    }else{
      prevData=props[0];currData=props[1]
    }
    let data={},setting={}
    Object.keys(currData.values).map(cat=>{
      setting[cat]=true;
      return 0;
    })
    if(setting['Operational Risk']||setting['Operational Reliability']||setting['Operational Efficiency'])
      data['Technology']={}
    if(setting['Training and Awareness']||setting['Information Security']||setting['Compliance'])
      data['People']={}
    if(setting['Frameworks']||setting['Audit']||setting['Governance'])
      data['Process']={}
    //Make Technology Data
    let prevOPrisk=prevData.values['Operational Risk'],
    prevOPreliability=prevData.values['Operational Reliability'],
    prevOPefficiency=prevData.values['Operational Efficiency'];
    let currOPrisk=currData.values['Operational Risk'],
    currOPreliability=currData.values['Operational Reliability'],
    currOPefficiency=currData.values['Operational Efficiency'];
    
    if(currData.values.hasOwnProperty('Operational Risk')){
      currData.values['Operational Risk']['changes']={}
      Object.keys(prevOPrisk['sub_categories']).map(subcat=>{
        currData.values['Operational Risk']['changes'][subcat]=prevOPrisk['sub_categories'][subcat]-currOPrisk['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Operational Reliability')){
      currData.values['Operational Reliability']['changes']={}
      Object.keys(prevOPreliability['sub_categories']).map(subcat=>{
        currData.values['Operational Reliability']['changes'][subcat]=prevOPreliability['sub_categories'][subcat]-currOPreliability['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Operational Efficiency')){
      currData.values['Operational Efficiency']['changes']={}
      Object.keys(prevOPefficiency['sub_categories']).map(subcat=>{
        currData.values['Operational Efficiency']['changes'][subcat]=prevOPefficiency['sub_categories'][subcat]-currOPefficiency['sub_categories'][subcat]
        return 0;
      })
    }
    if(setting['Operational Risk'])data['Technology']['Operational Risk']=currData.values['Operational Risk']
    if(setting['Operational Reliability'])data['Technology']['Operational Reliability']=currData.values['Operational Reliability']
    if(setting['Operational Efficiency'])data['Technology']['Operational Efficiency']=currData.values['Operational Efficiency']
    //Make People Data
    let prevTraining=prevData.values['Training and Awareness'],
    prevInformationSecurity=prevData.values['Information Security'],
    prevCompliance=prevData.values['Compliance'];
    let currTraining=prevData.values['Training and Awareness'],
    currInformationSecurity=prevData.values['Information Security'],
    currCompliance=prevData.values['Compliance'];
    if(currData.values.hasOwnProperty('Training and Awareness')){
      currData.values['Training and Awareness']['changes']={}
      Object.keys(prevTraining['sub_categories']).map(subcat=>{
        currData.values['Training and Awareness']['changes'][subcat]=prevTraining['sub_categories'][subcat]-currTraining['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Information Security')){
      currData.values['Information Security']['changes']={}
      Object.keys(prevInformationSecurity['sub_categories']).map(subcat=>{
        currData.values['Information Security']['changes'][subcat]=prevInformationSecurity['sub_categories'][subcat]-currInformationSecurity['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Compliance')){
      currData.values['Compliance']['changes']={}
      Object.keys(prevCompliance['sub_categories']).map(subcat=>{
        currData.values['Compliance']['changes'][subcat]=prevCompliance['sub_categories'][subcat]-currCompliance['sub_categories'][subcat]
        return 0;
      })
    }
    if(setting['Training and Awareness'])data['People']['Training and Awareness']=currData.values['Training and Awareness']
    if(setting['Information Security'])data['People']['Information Security']=currData.values['Information Security']
    if(setting['Compliance'])data['People']['Compliance']=currData.values['Compliance']

    //Make Process Data
    let prevAudit=prevData.values['Audit'],
    prevFrameworks=prevData.values['Frameworks'],
    prevGovernance=prevData.values['Governance'];
    let currAudit=prevData.values['Audit'],
    currFrameworks=prevData.values['Frameworks'],
    currGovernance=prevData.values['Governance'];

    if(currData.values.hasOwnProperty('Audit')){
      currData.values['Audit']['changes']={}
      Object.keys(prevAudit['sub_categories']).map(subcat=>{
        currData.values['Audit']['changes'][subcat]=prevAudit['sub_categories'][subcat]-currAudit['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Frameworks')){
      currData.values['Frameworks']['changes']={}
      Object.keys(prevFrameworks['sub_categories']).map(subcat=>{
        currData.values['Frameworks']['changes'][subcat]=prevFrameworks['sub_categories'][subcat]-currFrameworks['sub_categories'][subcat]
        return 0;
      })
    }
    if(currData.values.hasOwnProperty('Governance')){
      currData.values['Governance']['changes']={}
      Object.keys(prevGovernance['sub_categories']).map(subcat=>{
        currData.values['Governance']['changes'][subcat]=prevGovernance['sub_categories'][subcat]-currGovernance['sub_categories'][subcat]
        return 0;
      })
    }
    if(setting['Audit'])data['Process']['Audit']=currData.values['Audit']
    if(setting['Frameworks'])data['Process']['Frameworks']=currData.values['Frameworks']
    if(setting['Governance'])data['Process']['Governance']=currData.values['Governance']

    // logger.debug(data)
  
    return data;
}
//main2.js,categoryTrendsDialog.js
function fetchDataWithDate(startDate,trend=false){
  let url=urlConstants.SITE_URL+urlConstants.TRENDS_URL+urlConstants.TRENDS_READ_CATEGORY
  url=url+'?'+urlConstants.FILTER_START_DATE+'='+startDate
  // logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
      if(trend) return res.data
      if(res.data.length<2) {
        dateChanged=new Date(startDate);
        return fetchDataWithDate(commonService.getLastStartDate(startDate),false).then(res=>res)
      }
      return makeCategoryData(res.data)
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}

function fetchWeeklyCategoryData(){
  let startDate = new Date();
  startDate.setUTCDate(startDate.getUTCDate() - 8);
  startDate = commonService.getNowStartDate(startDate);
  let url=urlConstants.SITE_URL+urlConstants.TRENDS_URL+urlConstants.TRENDS_READ_CATEGORY
  url=url+'?limit=30&'+urlConstants.FILTER_START_DATE+'='+startDate
  // logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    return res.data;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchSubCategoryData(subCategory,type,startDate,trend=false){
      let url=urlConstants.SITE_URL+urlConstants.TRENDS_URL+urlConstants.TRENDS_READ_SUBCATEGORY
      if(startDate){
        url=url+'?'+urlConstants.FILTER_START_DATE+'='+startDate
      }
      const token=userService.getToken();
    // logger.debug(url,startDate)
      return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
      if(res.status!==401)
      {  
        if(trend) return res;
        if(res.data.length<2)
          return fetchSubCategoryData(subCategory,type,commonService.getLastStartDate(startDate),trend)
        if(type==='all') return res.data[0].values
        return res.data[0].values[subCategory]
      }
      else
        logout();
      }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
      })
}

//Top5Usecase.js,usecases.js,analysis_service.js,usecaseTrendsDialog.js,technologyTrends.js
function fetchTechnologyData(technology,filterUsecase=false,useCaseList,trend=false,startDate,count=1){
    technology=technology.toLowerCase()
    if(trend) return techComplianceTrends(technology,startDate).then(res=>res)
    else if(count===1){
      return latestTechCompliance(technology).then(res=>{
        if(!filterUsecase) return res;
        return res&&res.data?filterUsecasesUnderTechnologyUnderSubCategory(technology,useCaseList,res.data.usecase_compliance):null;
      })
    }
    else if(count>1){
      return howLatestTechComplianceWithLimit(technology,count,startDate).then(res=>{
        return res;
      })
    }
}
function latestTechCompliance(technology){
  technology=technology.toLowerCase()
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+'/'
  const token=userService.getToken();
  // logger.debug(url)
  // logger.debug(token);
  // console.log(url)
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
  // logger.debug(res)
  // console.log(res)
    if(res.status!==401)
    {  
      return res;
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchKnownVulnerabilities(){
  let url=urlConstants.SITE_URL+urlConstants.FETCH_KEV
  const token=userService.getToken();
  // logger.debug(url)
  // logger.debug(token);
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(res.status!==401)
    {  
      return res;
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function techComplianceTrends(technology,startDate){
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+urlConstants.TRENDS_URL
  url=url+'?'+urlConstants.FILTER_START_DATE+'='+startDate
// logger.debug(url)
//  console.log(url)
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(res.status!==401)
    {
      return res;
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function howLatestTechComplianceWithLimit(technology,count,startDate){
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+urlConstants.TRENDS_URL+'?limit='+count
// logger.debug(count);

  if(startDate)
    url=url+'&'+urlConstants.FILTER_START_DATE+'='+startDate
  const token=userService.getToken();
// logger.debug(url)
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    // logger.debug(res)
    if(res.status!==401)
    {
        return res.data;
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function howManYLatestTechCompliance(technology,count,startDate,loop){
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+urlConstants.TRENDS_URL
// logger.debug(count);
  if(startDate)
    url=url+'?'+urlConstants.FILTER_START_DATE+'='+startDate
  const token=userService.getToken();
// logger.debug(url)
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    // logger.debug(res)
    if(res.status!==401)
    {
      if(res.data.length>=count||loop>10)
      {
      // logger.debug("COUNTS",res.data.length,loop);
        return res;
      }
      return howManYLatestTechCompliance(technology,count,commonService.getLastStartDate(startDate),loop+1)
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function getTechStartDate(technology){
  technology=technology.toLowerCase()
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+'/'
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    // logger.debug(res.data.time)
    if(res.status!==401)
    {
      let date=new Date(res.data.time)
      date=commonService.getNowStartDate(date);
      // commonService.storeDate(technology,date)
      // localStorage.removeItem('techStartDate')
      // localStorage.setItem('techStartDate',JSON.stringify(date))
      return date;
    }
    else
      logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}

function getExecutiveSummary(){
  let url=urlConstants.SITE_URL
  url=url+urlConstants.EXECUTIVE_REPORT
  const token=userService.getToken();
  axios({
    baseURL: urlConstants.BASE_URL,
    url: url,
    method: 'GET',
    headers: {Authorization : `Bearer ${token}`},
    responseType: 'blob',
  }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'SMC-ExecutiveSummary.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  });
  
  
  
  
  
  
  
  
  
  
  
  
  // return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}, 'blob').then(res=>{
  //   // const writer = createWriteStream(outputLocationPath);
  //   console.log(res.data);
  //   if(res.status!==401)
  //   {
  //     const content = res.headers['content-type'];
  //     console.log(content)
  //     // let a = document.createElement("a");
  //     // a.setAttribute("download", 'Sample.pdf');
  //     // a.setAttribute("href", objectURL);
  //     // document.body.appendChild(a);
  //     // a.click();
  //     // document.body.removeChild(a);
  //   }
  //   else
  //     logout();
  // }).catch((error)=>{
  //   if(String(error).includes('401'))
  //   {
  //       console.log(error);
  //       logout();
  //   }
  //   else
  //     console.log(error);
  // })
}

function technologyPDF(selectedTechnology, fileName){
  // console.log(commonService.capitalizeletterWithDash(selectedTechnology).replace(' ', '-'))
  let url=urlConstants.SITE_URL
  url=url+urlConstants.PDF_REPORT+"?technology="+selectedTechnology
  const token=userService.getToken();
  axios({
    baseURL: urlConstants.BASE_URL,
    url: url,
    method: 'GET',
    headers: {Authorization : `Bearer ${token}`},
    responseType: 'blob',
  }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName+".pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  });
}