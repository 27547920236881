import React from 'react';
import styles from "@Utilities/scrollbar.module.css";
import DeviceChart from '@CommonComponents/ECharts/DeviceChart/DeviceChart';
import {Col} from 'react-bootstrap';
import classes from './UsecaseRow.module.css'
// import $ from 'jquery';

function UsecaseRow(props) {

    return (
        <div style={{display:"flex", width:"100%"}}>

            <div style={{display:"flex", width:"100%", height:"100%"}} className={styles.scrollbar_smc}>
                {props.data.map((usecase, i) => (
                    <div className={classes.content} key={i}>
                        <Col md={6}>
                            <DeviceChart data={usecase.useCaseDeviceData} 
                            name={usecase.useCaseName} tech={usecase.technologyName}/>
                        </Col>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default UsecaseRow