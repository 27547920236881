import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function UseCaseGaugeChart(props){
    const name = props.name ?? "UseCase Name"
    const score = props.score ?? 0

    const options = {
        series: [{
            type: 'gauge',
            radius: '90%',
            axisTick: { show:false },
            splitLine: { show:false },
            axisLabel: { show:false },
            // startAngle: 240,
            // endAngle: -60,
            // min: 0,
            // max: 100,
            // splitNumber: 10,
            axisLine: {
                lineStyle: {
                    width: 5,
                    color: [
                        [0.33, '#FF6E76'],
                        [0.66, '#FDDD60'],
                        [1, '#15CF1A']
                    ]
                }
            },
            lengend: {
                show: false
            },
            pointer: {
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '42%',
                width: 10,
                offsetCenter: [0, '-35%'],
                itemStyle: {
                    color: 'auto'
                }
            },
            // axisTick: {
            //     length: 2,
            //     lineStyle: {
            //         color: 'auto',
            //         width: 1
            //     }
            // },
            // splitLine: {
            //     length: 5,
            //     lineStyle: {
            //         color: 'auto',
            //         width: 2
            //     }
            // },
            detail: {
                fontSize: 18,
                offsetCenter: [0, '90%'],
                valueAnimation: true,
                formatter: value => Math.round(value) + '%',
                color: '#000'
            },
            data: [{
                value: score
            }]
        }]
    };

    return (
        <div style={{}}>
        <ReactECharts 
            option={options}
            style={{
                width:"100%",
                height:"100%"
            }} 
        />
        <p 
            style={{
                textAlign:"center", 
                fontSize:"1.2em",
                lineHeight: 1
        }}>{commonService.capitalizeletterWith_(name)}</p>
        </div>
    )
}
export default UseCaseGaugeChart;