import React,{useState, useRef} from 'react';
import classes from './CreateUserPage.module.css';
import styles from "@Utilities/scrollbar.module.css";
import { userService, commonService } from '@Services';
import {FaCheckCircle} from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function CreateUserPage(props) {
    const [loginError, setLoginError] = useState();
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const userNameRef = useRef();
    const firstNameRef = useRef();
    const secondNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const adminUserRef = useRef();
    
    function submitHandler(event) {
        event.preventDefault();
        const enteredUserName = userNameRef.current.value;
        const enteredFirstName = firstNameRef.current.value;
        const enteredSecondName = secondNameRef.current.value;
        const enteredEmail = emailRef.current.value;
        const enteredPassword = passwordRef.current.value;
        const enteredConfirmPassword = confirmPasswordRef.current.value;
        const adminUser = adminUserRef.current.checked;
        if(enteredUserName.length===0)
        {
            setLoginError('User Name is Mandatory Field...');
        }
        else if(enteredFirstName.length===0)
        {
            setLoginError('First Name is Mandatory Field...');
        }
        else if(enteredSecondName.length===0)
        {
            setLoginError('Second Name is Mandatory Field...');
        }
        else if(enteredEmail.length===0)
        {
            setLoginError('Email Address is Mandatory Field...');
        }
        else if(enteredPassword.length===0)
        {
            setLoginError('Password is Mandatory Field...');
        }
        else if(enteredConfirmPassword.length===0)
        {
            setLoginError('Confirm Password is Mandatory Field...');
        }
        else if(enteredPassword !== enteredConfirmPassword)
        {
            setLoginError('Passwords are not matching...');
        }
        else
        {
            const payload ={
                username: enteredUserName,
                first_name: enteredFirstName,
                last_name: enteredSecondName,
                email: enteredEmail,
                password: enteredPassword,
                confirm_password: enteredConfirmPassword,
                is_superuser: adminUser
            }
            userService.register(payload).then(response => {
                    if(response.status === 201)
                    {
                        setSubmissionStatus(true);
                    }
                    else
                    {
                        // console.log(response.response.data);
                        setLoginError('Could Not Create the User');
                    }
                }
            ).catch( exp => {
                if(exp.message === "Request failed with status code 401")
                    setLoginError("Invalid User/Password")
                else
                {
                    // console.log(exp);
                    setLoginError(exp.message)
                }
            });
        }
        
    }


    return (
        <div className={styles.scrollbar_smc}>
            <div style={{maxWidth: '700px'}}>
                <div className={classes.center}>
                    <h2><u>Register A New User</u></h2>
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'username'}>{commonService.capitalizeletterWith_('username')} :</label>
                    <input
                        type='text'
                        id='username'
                        placeholder='Please Enter User Name'
                        ref={userNameRef}
                        required={true}
                        size={30}
                        />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'first_name'}>{commonService.capitalizeletterWith_('first_name')} :</label>
                    <input
                        type='text'
                        id='first_name'
                        placeholder='Please Enter First Name'
                        ref={firstNameRef}
                        required={true}
                        size={30}
                        />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'last_name'}>{commonService.capitalizeletterWith_('last_name')} :</label>
                    <input
                        type='text'
                        id='last_name'
                        placeholder='Please Enter Last Name'
                        ref={secondNameRef}
                        required={true}
                        size={30}
                        />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'email'}>{commonService.capitalizeletterWith_('email')} :</label>
                    <input
                        type='email'
                        id='email'
                        placeholder='Please Enter Email Address'
                        ref={emailRef}
                        required={true}
                        size={30}
                        />    
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'password'}>{commonService.capitalizeletterWith_('password')} :</label>
                    <input
                        type='password'
                        id='password'
                        placeholder='Please Enter Password'
                        pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                        ref={passwordRef}
                        required={true}
                        size={30}
                        />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor={'confirm_password'}>{commonService.capitalizeletterWith_('confirm_password')} :</label>
                    <input
                        type='password'
                        id='confirm_password'
                        placeholder='Please Confirm Password'
                        pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                        ref={confirmPasswordRef}
                        required={true}
                        size={30}
                        />
                </div>
                <label htmlFor={'admin'}>&nbsp;{commonService.capitalizeletterWith_('admin_user')}&nbsp;&nbsp;  </label>
                <input type="checkbox" id="admin" name="admin" value="admin" ref={adminUserRef}/>

                <div className={classes.right}>
                    {submissionStatus?<FaCheckCircle size={35}/>:null}
                    <Button variant='secondary' color='primary' 
                        size='small' onClick={submitHandler} >
                                Submit
                    </Button>
                </div>
                <div className={classes.center}>
                    {loginError?
                    <div style={{textAlign: 'center', 
                    fontSize: 18,
                    color: 'red',
                    marginTop: 0}}>{loginError}</div>:null}
                </div>
            </div>
        </div>
    );
}

export default CreateUserPage;