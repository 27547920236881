import Modal from 'react-bootstrap/Modal';
import React, {useState, useEffect} from 'react';
import { deviceService, commonService } from '@Services';
import Details from './Details';
import styles from "@Utilities/scrollbar.module.css";
import {Loading} from '@CommonComponents/Loading/Loading';
import classes from './style.module.css'


function DeviceModal(props){
    const technology = props.techName ?? '';
    const startDate = props.startDate ?? '';
    const systemName = props.systemName ?? '';

    const [deviceData, setDeviceData] = useState();

    useEffect(() => {
        deviceService.fetchDeviceDetails(technology,startDate,systemName).then((deviceDetails)=>{
            // logger(deviceDetails);
            setDeviceData(deviceDetails[0]);
        });
    },[technology, startDate, systemName]);

    return (
        <Modal
            show={props.show}
            onHide={() => props.modalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {commonService.capitalizeletterWithDash(technology)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.modalBody+' '+styles.scrollbar_smc}>
                {deviceData?
                <div className={classes.deviceListRoot}>
                    {Object.keys(deviceData).map((detail, i)=>{
                        return <Details property={detail} data={ deviceData[detail] } tabbing={0} key={i} />
                    })}
                </div>
                :<Loading />}
            </Modal.Body>
        </Modal>
    );
}

export default DeviceModal;