import React,{useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import classes from './UseCaseModal.module.css'
import UseCaseGaugeChart from '../Chart/UseCaseGaugeChart';
import DeviceList from '@CommonComponents/DeviceList/DeviceList';
import styles from '@Utilities/scrollbar.module.css';
import { commonService } from '@Services';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { smcUtility } from '@Utilities';
import { userService } from '@Services';


function UseCaseModal(props){
    const technology = props.techName ?? '';
    const [openDeviceList, setOpenDeviceList] = useState(false);
    const [clickedUseCaseName, setClickedUseCaseName] = useState(false);
    const [clickedUseCaseScore, setClickedUseCaseScore] = useState(false);
    const [showAutomation, setShowAutomation] = useState(false);

    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            if(data.automation.show)
            {
                userService.checkAdmin().then((response)=>{
                    setShowAutomation(response);
                });
            }
        });
    },[]);

    function onUseCaseClick(usecaseDetails){
        // console.log(usecaseDetails);
        setClickedUseCaseScore(usecaseDetails.score);
        setClickedUseCaseName(usecaseDetails.name);
        setOpenDeviceList(true);
    }
    return (
        <React.Fragment>
        <Modal
            show={props.show}
            onHide={() => props.modalShow(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
            data-for='usecase-box' data-tip='Click the speedometer to get device list '
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {commonService.capitalizeletterWithDash(technology)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.gridContainer}>
                    {props.data.map( (usecase,i) => (
                        <div className={classes.gridItem} key={i} onClick={()=>onUseCaseClick(usecase)}>
                            <UseCaseGaugeChart name={usecase.name} score={usecase.score} width="150px" height="150px"/>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <ReactTooltip id='usecase-box' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099' effect='float' />
        </Modal>
            {openDeviceList?
                <Modal
                    show={openDeviceList}
                    onHide={() => setOpenDeviceList(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                >
                    <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {commonService.capitalizeletterWithDash(technology)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{backgroundColor: "rgb(40,41,42)"}} className={styles.scrollbar_smc}>
                        <DeviceList 
                            techName={technology} 
                            usecase={clickedUseCaseName} 
                            usecaseScore={clickedUseCaseScore}
                            showHeader={true}
                            showAutomationCheckbox={showAutomation}
                        />
                    </Modal.Body>
                </Modal>
            :null}
        </React.Fragment>
    );
}

export default UseCaseModal;