import React,{useEffect,useState} from 'react';
import {annotateService,deviceService,commonService} from '@Services';
import Card from '@CommonComponents/Card/Card';
import {Container, Row, Col} from 'react-bootstrap';
import classes from '../../../../../../common-components/DeviceList/DeviceList.module.css';
import { FaSquare } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
// import PieChart from './PieChart'
import InsightTechnology from '../../../InsightTechnology'
import DeviceModal from '../../../../../../common-components/DeviceList/components/DeviceModal';

function SkyHigh(){
    const technologyName = 'webgateway-skyhighsecurity'
    const usecase = 'health_check'
    const startDate = '';

    const [tableData, setTableData] = useState()
    const [techAnnotationData, setTechAnnotationData] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState();

    useEffect(()=>{
        deviceService.getTechDataStartDate(technologyName).then(startDate =>{
            deviceService.fetchTechDevices(technologyName,usecase,startDate,commonService.getNextQuarterDate(startDate),'','','','').then((res)=>{
                // console.log(res)
                let list = []
                res.data.map((elm,i)=>{
                    if (i<3){
                        list.push(elm)
                    }
                })
                // console.log(list)
                setTableData(list)
            })
        })
    },[])

    useEffect(()=>{
        annotateService.getTechStartDate(technologyName).then((startDate)=>{
            annotateService.annotate(technologyName,startDate).then((res)=>{
                let annotateData = []
                Object.keys(res).map((columnName,index)=>{
                    if(columnName !== "time")
                    {
                        const data={
                            name: columnName,
                            data: res[columnName]
                        }
                        annotateData.push(data);
                    }
                    return 0;
                });
                setTechAnnotationData(annotateData);
            });
        });
    },[]);

    function showModal(state){
        setModalShow(state);
    }

    function handleClick(data){
        const systemNameFilter = commonService.classifyPrimaryKey(technologyName);
        const systemName = data[systemNameFilter];
        setModalData(systemName);
        showModal(true);
    }

    return(
        <React.Fragment>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:'0px',marginBottom:"15px",marginRight:'5px'}} >
                    {tableData?
                        <table className={classes.technologies}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>System Name</th>
                                    <th style={{textAlign:"center"}}colSpan={7}>Weekly Alert Trend</th>
                                </tr>
                                <tr>
                                    <th style={{textAlign:"center"}}>Mon</th>
                                    <th style={{textAlign:"center"}}>Tue</th>
                                    <th style={{textAlign:"center"}}>Wed</th>
                                    <th style={{textAlign:"center"}}>Thu</th>
                                    <th style={{textAlign:"center"}}>Fri</th>
                                    <th style={{textAlign:"center"}}>Sat</th>
                                    <th style={{textAlign:"center"}}>Sun</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((system,i)=>{
                                    return(
                                        <tr onClick={()=>{handleClick(system)}}>
                                            <td>{system['system_name']}</td>
                                                {system[usecase].map((alert,k)=>{
                                                    return(
                                                        <td>
                                                            <div data-tooltip-id='alert' data-tooltip-content={alert} style={{display:'inline-block'}}>
                                                                <FaSquare style={alert==="OK"?{color:'green'}:{color:'red'}}/>&nbsp;
                                                                <Tooltip id='alert' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099'  />
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    :null}
                </Row>
                <InsightTechnology techName={technologyName}/>
                {modalShow?
                    <DeviceModal 
                        show={modalShow} 
                        modalShow={showModal} 
                        systemName={modalData} 
                        techName={technologyName} 
                        startDate={startDate}
                    />
                :null}
            </Container>
        </React.Fragment>
    )
}

export default SkyHigh;