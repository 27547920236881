import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function BarChart(props) {

    const name = props.name ? commonService.capitalizeletterWith_(props.name) : "ANC"
    let xAxisData = [];
    let yAxisData = [];
    let colors = props.colors? props.colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']

    if(props.data)
    {
        const propsData = props.data;
        const propName = props.name;
        for (const [ , value] of propsData.entries()) {
            xAxisData.push(String(value[propName]));
            yAxisData.push(value.device_count);   
        }
    }


    const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return `${name}<br />
                    <div style="background-color:${params[0].color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params[0].name}: ${params[0].value}<br />`;
          },
          textStyle: {
            color: "#000",
            fontWeight: "600",
            fontSize: "1.2em",
          },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                  color: '#fff',
                  fontSize: '.8em'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                  color: '#fff',
                  fontSize: '.8em'
                }
            }
        ],
        series: [
            {
                name: '',
                type: 'bar',
                barWidth: '60%',
                height: '100%',
                data: yAxisData,
                itemStyle: {
                  color: function (param) {
                      // console.log(param);
                      return colors[param.dataIndex];
                  }
              }
            }
        ]
    };
    function handleChartClick(params){
        // console.log(params);
          const filterBy = props.name;
          const filterValue = params.name;
          const totalCount = params.value;
          const filter = filterBy+'='+filterValue
          props.handleClick(filter, totalCount);
    }
    return (
        <div>
          <ReactECharts 
              option={options}
              style={{
                  width:"100%",
                  // height:"250px"
              }} 
              onEvents={{'click':handleChartClick}}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1em",
              fontWeight: 700,
            }}
          >
            {name}
          </p>
        </div>
    )
}



export default BarChart