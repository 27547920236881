// import React from 'react';
import ReactEcharts from "echarts-for-react";
import { commonService } from "@Services";

function PieChart(props) {
  const name = props.name
    ? commonService.capitalizeletterWith_(props.name)
    : "ANC";
  let data = null;
  let colors = props.colors? props.colors: [];

  const dummyData = [
    { value: 60, name: "A" },
    { value: 40, name: "B" },
    { value: 20, name: "C" },
    { value: 80, name: "D" },
    { value: 100, name: "E" },
  ];

  if (props.data) {
    const propsData = props.data;
    const propName = props.name;
    let arrangedData = [];
    for (const [index, value] of propsData.entries()) {
      const chartDatam = {
        name: String(value[propName]),
        value: value.device_count,
      };
      arrangedData.push(chartDatam);
    }
    data = arrangedData;
  }

  function handleChartClick(params) {
    // console.log(params);
    const filterBy = props.name;
    const filterValue = params.data.name;
    const totalCount = params.data.value;
    const filter = filterBy + "=" + filterValue;
    props.handleClick(filter, totalCount);
  }

  const option = {
    tooltip: {
      trigger: "item",
      // formatter: "{a} <br/>{b} : {c}({d}%)"
      formatter: function (params) {
        return `${name}<br />
                        <div style="background-color:${params.color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params.name}: ${params.data.value} (${params.percent}%)<br />`;
      },
      textStyle: {
        color: "#000",
        fontWeight: "600",
        fontSize: "1.2em",
      },
    },

    legend: {
      type: "scroll",
      left: "55%",
      top: "10%",
      width: '40%',
      orient: "vertical",
      pageIconInactiveColor: "#475067",
      pageTextStyle: {
        color: "#abc",
        fontWeight: 500,
        fontSize: 10,
      },
      pageIconColor: "#bbb",
      textStyle: {
        color: "#fff",
        fontSize: 11,
        fontWeight: 400,
        width: '200',
        overflow: 'truncate'
      },
      borderColor: "#fff",
      borderWidth: 1,
      borderRadius: 10,
      padding: 10
    },
    series: [
      {
        top: '5%',
        name: name,
        type: "pie",
        roseType: 'area',
        color: [...colors, '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        center: props.center ? props.center : ["25%", "50%"],
        radius: "80%",
        data: data,
        itemStyle: {
          borderRadius: 0,
          borderColor: "#222",
          borderWidth: 0,
        },
        label: {
          show: false,
          fontSize: "1.2em",
          color: "black",
          position: "center",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  return (
    <div>
      <ReactEcharts
        style={{
          // height:'250px',
          width: "100%",
        }}
        option={option}
        onEvents={{ click: handleChartClick }}
      />
      <p
        style={{
          textAlign: "center",
          fontSize: "1em",
          fontWeight: 700,
        }}
      >
        {name}
      </p>
    </div>
  );
}
export default PieChart;
