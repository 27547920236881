import ReactECharts from "echarts-for-react";
import { commonService } from "@Services";
import { color } from "echarts";

function DoughnutChart(props) {
  const name = props.name
    ? commonService.capitalizeletterWith_(props.name)
    : "ANC";
  let data = null;
  let colors = props.colors? props.colors: [];
  const dummyData = [
    { value: 1048, name: "A" },
    { value: 735, name: "B" },
    { value: 580, name: "C" },
    { value: 484, name: "D" },
    { value: 300, name: "E" },
  ];

  if (props.data) {
    const propsData = props.data;
    const propName = props.name;
    // console.log();
    let arrangedData = [];
    for (const [index, value] of propsData.entries()) {
      const chartDatam = {
        name: String(value[propName]),
        value: value.device_count,
      };
      // console.log(chartDatam);
      arrangedData.push(chartDatam);
    }
    data = arrangedData;
  }

  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${name}<br />
                        <div style="background-color:${params.color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params.name}: ${params.data.value} (${params.percent}%)<br />`;
      },
      textStyle: {
        color: "#000",
        fontWeight: "600",
        fontSize: "1.2em",
      },
    },
    legend: {
      pageIconInactiveColor: "#475067",
      width: '40%',
      pageTextStyle: {
        color: "#abc",
        fontWeight: 500,
        fontSize: 10,
      },
      type: "scroll",
      top: "10%",
      left: "55%",
      orient: "vertical",
      textStyle: {
        color: "#fff",
        fontSize: 11,
        fontWeight: 400,
        width: '200',
        overflow: 'truncate'
      },
      borderColor: "#fff",
      borderWidth: 1,
      borderRadius: 10,
      padding: 10,
    },
    series: [
      {
        name: name,
        type: "pie",
        radius: ["45%", "75%"],
        center: props.center ? props.center : ["25%", "50%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 0,
          borderColor: "#222",
          borderWidth: 0,
        },
        color: [...colors, '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        label: {
          show: false,
          fontSize: "1.2em",
          position: "center",
        },
        emphasis: {
          // label: {
          //     show: true,
          //     fontSize: '30',
          //     fontWeight: 'bold',
          // },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          show: false,
        },
        data: data ?? dummyData,
      },
    ],
  };
  function handleChartClick(params) {
    // console.log(params);
    const filterBy = props.name;
    const filterValue = params.data.name;
    const totalCount = params.data.value;
    const filter = filterBy + "=" + filterValue;
    props.handleClick(filter, totalCount);
  }
  return (
    <div>
      <ReactECharts
        option={options}
        style={{
          width: "100%",
          // height:"250px"
        }}
        onEvents={{ click: handleChartClick }}
      />
      <p
        style={{
          textAlign: "center",
          fontSize: "1em",
          fontWeight: 700,
        }}
      >
        {name}
      </p>
    </div>
  );
}

export default DoughnutChart;
