export const urlConstants = {
  BASE_URL:'https://app.mysmartsmc.com:8443',
  // BASE_URL:'http://127.0.0.1:7000',
  SITE_URL: '/api',
  TRENDS_URL: '/trends',
  TRENDS_READ_CATEGORY: '/read-categories/',
  TRENDS_READ_SUBCATEGORY: '/read-sub-categories/',

  INFRA_PEOPLE_A_URL: '/people',
  TECH_PEOPLE_A_URL: '/people/',

  USECASE_COMPLIANCE: 'compliance',
  WEIGHTAGE_URL: '/smc-admin/',
  ADMIN_URL: 'admin/',
  TRENDS_WRITE_URL: 'write-tech/',
  AUTH_LOGIN_URL: '/v1/auth/login/',
  AUTH_LOGOUT_URL: '/v1/auth/logout/',
  AUTH_PROFILE_URL: '/v1/auth/profile/',
  AUTH_PASSWORD_CHANGE_URL: '/v1/auth/password-change/',
  AUTH_TOKEN_VERIFY_URL: '/v1/auth/token-verify/',
  AUTH_TOKEN_REFRESH_URL: '/v1/auth/token-refresh/',
  AUTH_REGISTER_URL: '/v1/auth/register/',
  EOL: '/eol/data/',
  CREATE_TASK:'/tasks/create-request/',
  FETCH_TASK:'/tasks/read-request/',
  FETCH_KEV:'/known_vulnerabilities/',
  EXECUTIVE_REPORT:'/reports/exec-summary',
  PDF_REPORT:'/reports/tech-report',

  FILTER_START_DATE: 'startDate',
  FILTER_END_DATE: 'endDate',

  'FILTER_hips-mcafee': 'system_name',
  'IMP_FILTER_hips-mcafee':',time,system_name,ip_address,',
  
  // 'hips-mcafee':'/mcafee/hips/',
  // 'firewallanalyzer-algosec':'/algosec/firewall-analyzer/',
  // 'antivirus-symantec':'/symantec/antivirus/',
  // 'dlpdiscover-symantec':'/symantec/dlp-discover/',
  // 'dlpendpoint-symantec':'/symantec/dlp-endpoint/',
  // 'dlp-symantec':'/symantec/dlp/',
  // 'loadbalancer-f5':'/f5/load-balancer/',
  'cmdb-servicenow':'/servicenow/cmdb/',
  'incidents-servicenow':'/servicenow/incidents/',
  // 'bluecoatproxy-symantec':'/symantec/proxy/',
  // 'firewallmanagement-checkpoint':'/checkpoint/firewall-mgmt/',
  'secureinternetgateway-zscaler':'/zscaler/zia/',
  // 'vpn-pulsesecure':'/pulsesecure/vpn/',
  // 'dlp-mcafee':'/mcafee/dlp/',
  // 'bitlockerencryption-microsoft':'/microsoft/bitlocker/',
  // 'falcon-metrics': '/crowdstrike/falcon/metrics',
  // 'falcon-metrics-admin': '/crowdstrike/falcon/admin/',
  // 'firepowermanagement-cisco':'/cisco/fmc/',
  // 'umbrella-cisco':'/cisco/umbrella/',
  // 'centralmanagement-fireeye':'/fireeye/cm/',
  // 'nsm-mcafee':'/mcafee/nsm/',
  // 'hosts-crowdstrike':'/crowdstrike/hosts/',
  // 'sccmhosts-microsoft':'/microsoft/sccm/hosts/',
  // 'hosts-zscaler':'/zscaler/hosts/',
  'antivirus-microsoft':'/microsoft/antivirus/',
  // 'hardening-cis':'/cis/server/',
  // 'patching-microsoft':'/microsoft/patching/',
  'people-people':'/people/people/',
  'process-process':'/process/process/',
  's3-aws':'/aws/s3/',
  'ec2-aws':'/aws/ec2/',
  'monitoring-solarwinds':'/solarwinds/sys-mgmt/',
  'firewall-skyboxsecurity':'/skyboxsecurity/firewall-assurance/',
  'av-mcafee':'/mcafee/av/',
  'panorama-paloaltonetworks':'/paloaltonetworks/panorama/',
  'loadbalancer-barracuda':'/barracuda/load-balancer/',
  'vulnerabilitymanagement-tenable':'/tenable/vul-mgmt/',
  'firewallanalyzer-firemon':'/firemon/security-manager/',
  'waf-cloudflare': '/cloudflare/waf/',
  // 'panorama-paloaltonetworks':'/paloaltonetworks/panorama/',
  'incidents-cybershield':'/cybershield/incidents/',
  'bluecoatproxysg-symantec':'/symantec/proxy_sg/',
  'webgateway-skyhighsecurity':'/skyhighsecurity/web_gateway/',
  'dlp-symantec':'/symantec/dlp/'
};
