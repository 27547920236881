import React,{useState,useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ScoreGauge from '@CommonComponents/ScoreGauge/ScoreGauge';
import ScoreArrow from '@CommonComponents/ScoreArrow/ScoreArrow';
import { commonService, dataService } from '@Services';
import DeviceList from '@CommonComponents/DeviceList2/DeviceList2';
import styles from '@Utilities/scrollbar.module.css';
import TechnologyGauge from '@CommonComponents/ECharts/TechnologyGauge/TechnologyGauge';
import { useNavigate } from "react-router-dom";
import { smcUtility } from '@Utilities';
import { userService } from '@Services';

function UsecaseDetails() {
    const curURL = window.location.href;
    let crURL = curURL.split("?")[0];
    crURL = crURL.split("/");
    const usecase = crURL[crURL.length-1].replaceAll("%20"," ");
    const technology = crURL[crURL.length-2].replaceAll("%20"," ");
    const url = new URL(curURL);
    const deviceCount = url.searchParams.get("deviceCount").replaceAll("%20"," ");
    const type = url.searchParams.get("type").replaceAll("%20"," ");
    const navigate = useNavigate();

    const [usecaseScore, setUsecaseScore] = useState(0);
    const [catName, setCatName] = useState('');
    const [subcatName, setSubcatName] = useState('');
    const [technologyScore, setTechnologyScore] = useState(0);
    const [catScore, setCatScore] = useState(0);
    const [subcatScore, setSubcatScore] = useState(0);
    const [subcatScoreChange, setSubcatScoreChange] = useState(0);
    const [showAutomation, setShowAutomation] = useState(false);

    function showCategories() {
        navigate('/categories');
    }
    function showSubCategories(subcategory) {
        const url = "/sub-categories/"+subcategory
        navigate(url);
    }

    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            if(data.automation.show)
            {
                userService.checkAdmin().then((response)=>{
                    setShowAutomation(response);
                });
            }
        });
        const startDate = commonService.getNowStartDate();
        dataService.fetchDataWithDate(startDate).then((categoryData)=>{
            dataService.fetchSubCategoryData('','all',startDate,false).then((subCategoryData)=>{
                Object.keys(subCategoryData).map((subcat,index)=>{
                    if(subCategoryData[subcat].hasOwnProperty(technology))
                    {
                        if(subCategoryData[subcat][technology]['usecases'].hasOwnProperty(usecase))
                        {
                            setUsecaseScore(subCategoryData[subcat][technology]['usecases'][usecase]);
                            setSubcatName(subcat);
                            setTechnologyScore(subCategoryData[subcat][technology].score);
                            Object.keys(categoryData.Technology).map((catdata)=>{
                                if(categoryData.Technology[catdata].sub_categories.hasOwnProperty(subcat))
                                {
                                    setCatName(catdata.split(' ')[1]);
                                    setCatScore(categoryData.Technology[catdata].score);
                                    setSubcatScore(categoryData.Technology[catdata].sub_categories[subcat]);
                                    setSubcatScoreChange(categoryData.Technology[catdata].changes[subcat])
                                }
                                return 0;
                            })
                        }
                    }
                    return 0;
                })
            })
        })
    },[technology, usecase])

    return (
        <div className={styles.scrollbar_smc}>
        <Container fluid>
            <Row style={{height:"100%"}}>
                <Col md={2}>
                    <div onClick={showCategories}>
                    <ScoreGauge 
                        value={catScore} 
                        category={catName}
                        disableText={false}
                        style={{
                            width: 200
                        }} 
                    />
                    </div>
                    <div onClick={()=>showSubCategories(subcatName)}>
                    <ScoreArrow 
                        score={subcatScore+" %"} 
                        subcategory={subcatName}
                        change={subcatScoreChange}
                    />
                    </div>
                    <center>
                    <TechnologyGauge 
                        name={commonService.technologyKnownAs(technology)}
                        score={technologyScore}/>
                        </center>
                </Col>
                <Col 
                    md={10} 
                    style={{
                        display:"grid", 
                        alignItems: "start",
                    }}
                >
                    <DeviceList 
                        techName={technology} 
                        usecase={usecase} 
                        showHeader={true} 
                        usecaseScore={usecaseScore}
                        showAutomationCheckbox={showAutomation}
                        deviceCountofThisType={parseInt(deviceCount)}
                        type={type}
                    />
                </Col>
            </Row>
        </Container>
    </div>
    )
}
export default UsecaseDetails