import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';


function TechnologyGauge(props) {
    const score = props.score ?? 30;
    const propName = props.name ?? 'usecase_name';
    const name = commonService.capitalizeletterWithDash(propName);
    
    const options = {
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%' 
        },
        series: {
            name: 'Score',
            type: 'gauge',
            radius: '80%',
            progress: {
                show: true
            },
            axisTick: { show:false },
            splitLine: { show:false },
            pointer: {
                show: false,
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '60%',
                width: 10,
                offsetCenter: [0, '-10%'],
                itemStyle: {
                    color: 'auto'
                }
            },
            axisLabel: { show:false },
            detail: {
                valueAnimation: true,
                formatter: '{value} %',
                fontSize: 18,
                color: '#fff',
                offsetCenter: [0, 0],

            },
            data: [{
                value: score,
                name: 'SCORE',
                title:{show:false},
                itemStyle: {
                    color: commonService.colorCodeBasedOnScore(score)
                }
            }]
        }
    };

    return (
        <div 
            style={{
                width:"fit-content", 
                display:"grid", 
                cursor:"pointer"
            }}
        >
            <ReactECharts 
                option={options}
                style={{
                    width:"150px",
                    height: "140px",
                    marginButtom: '-10px'
                }}
            />
            <p 
                style={{
                    textAlign:"center", 
                    alignSelf:"flex-end",
                    marginTop: '-20px',
                    fontSize: '.6em',
                    color: '#7E8BE9',
                    textShadow: '0px 2px 2px #000000c7'
            }}>{name}</p>
        </div>
    )
}



export default TechnologyGauge