import React, {useState, useEffect} from 'react';
import { commonService, eolService } from '@Services';
import classes from './EndOfLifePage.module.css';
import Button from 'react-bootstrap/Button';
import { LoadingFull } from '@CommonComponents/Loading/Loading';

function EndOfLifePage(props){

    const [technologiesData, setTechnologiesData] = useState();
    const [selectedTechnology, setSelectedTechnology] = useState();
    const [deviceCount, setDeviceCount] = useState();
    const [deviceData, setDeviceData] = useState();
    const [offSet, setOffSet] = useState(0);
    const [params, setParams] = useState('');
    const [pageLength, setPageLength] = useState();
    const [expiredSelected, setExpiredSelected] = useState(true);
    const [inSupportSelected, setInSupportSelected] = useState(true);
    const [versionsData, setVersionsData] = useState();
    const [selectedVersion, setSelectedVersion] = useState('all');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    function onTechChange(event){
        setDeviceData(null);
        setSelectedTechnology(event.target.value);
        setDeviceCount(technologiesData[event.target.value]);
        setOffSet(0);
        setParams('');
        setExpiredSelected(true);
        setInSupportSelected(true);
        setVersionsData(null);
        setSelectedVersion('all');
        setStartDate(null);
        setEndDate(null);
    }
    function onVersionChange(event){
        if(event.target.value !== 'all')
        {
            setDeviceData(null);
            setSelectedVersion(event.target.value);
            setOffSet(0);
            setExpiredSelected(true);
            setInSupportSelected(true);
            setDeviceCount(versionsData[event.target.value]);
            const parameter = 'version='+event.target.value;
            setParams(parameter);
            setStartDate(null);
            setEndDate(null);
        }
        else
        {
            setDeviceData(null);
            setSelectedVersion('all');
            setOffSet(0);
            setExpiredSelected(true);
            setInSupportSelected(true);
            setDeviceCount(technologiesData[selectedTechnology]);
            setParams('');
            setStartDate(null);
            setEndDate(null);
        }
    }
    function handleNext() {
        const os = parseInt(offSet) + pageLength;
        setOffSet(os);
    }
    function handlePrev() {
        const os = parseInt(offSet) - pageLength;
        if(parseInt(os)<0)
            setOffSet(0);
        else
            setOffSet(os);
    }
    function settingStartDate(event){
        setSelectedVersion('all');
        setStartDate(event.target.value);
        if(params)
        {
            let prms = params;
            prms+='&eol__gte='+event.target.value;
            setParams(prms);
        }
        else
        {
            const prms = 'eol__gte='+event.target.value;
            setParams(prms);
        }
    }
    function settingEndDate(event){
        setSelectedVersion('all');
        setEndDate(event.target.value);
        if(params)
        {
            let prms = params;
            prms+='&eol__lte='+event.target.value;
            setParams(prms);
        }
        else
        {
            const prms = 'eol__lte='+event.target.value;
            setParams(prms);
        }
    }
    function settingInSupportSelected(event){
        setSelectedVersion('all');
        if(!inSupportSelected){
            setInSupportSelected(true);
            if(!expiredSelected)
            {
                if(params)
                {
                    let prms = params;
                    prms+='&expired=false';
                    setParams(prms);
                }
                else
                {
                    const prms = 'expired=false';
                    setParams(prms);
                }
            }
            else
            {
                if(params)
                {
                    let newParams = ''
                    const para = params.split('&');
                    for (const [ , value] of para) {
                        if(value && !value.includes('expired')){
                            if(newParams)
                            {
                                newParams+='&'+value;
                            }
                            else
                            {
                                newParams=value;
                            }
                        }
                    }
                    setParams(newParams);
                }
            }
        }
        else{
            if(expiredSelected){
                setInSupportSelected(false);
                if(params)
                {
                    let prms = params;
                    prms+='&expired=true';
                    setParams(prms);
                }
                else
                {
                    const prms = 'expired=true';
                    setParams(prms);
                }
            }
            else{
                alert('Atleast one of expired/insupport should be checked.');
                setInSupportSelected(true);
                setExpiredSelected(true);
                setStartDate(null);
                setEndDate(null);
                setSelectedVersion('all');
                setParams('');
            }
        }
    }
    function settingExpiredSelected(event){
        setSelectedVersion('all');
        if(!expiredSelected){
            setExpiredSelected(true);
            if(!inSupportSelected)
            {
                if(params)
                {
                    let prms = params;
                    prms+='&expired=true';
                    setParams(prms);
                }
                else
                {
                    const prms = 'expired=true';
                    setParams(prms);
                }
            }
            else
            {
                if(params)
                {
                    let newParams = ''
                    const para = params.split('&');
                    for (const [ , value] of para) {
                        if(value && !value.includes('expired')){
                            if(newParams)
                            {
                                newParams+='&'+value;
                            }
                            else
                            {
                                newParams=value;
                            }
                        }
                    }
                    setParams(newParams);
                }
            }
        }
        else{
            if(inSupportSelected){
                setExpiredSelected(false);
                if(params)
                {
                    let prms = params;
                    prms+='&expired=false';
                    setParams(prms);
                }
                else
                {
                    const prms = 'expired=false';
                    setParams(prms);
                }
            }
            else{
                alert('Atleast one of expired/insupport should be checked.');
                setInSupportSelected(true);
                setExpiredSelected(true);
                setStartDate(null);
                setEndDate(null);
                setSelectedVersion('all');
                setParams('');
            }
        }
    }

    useEffect(() => {
        eolService.getTechnologies().then((data)=>{
            let technologies = {}
          // console.log(data);
            for (const[index,value] of data.entries()){
                technologies={
                    ...technologies,
                    [value.technology_name]: value.device_count
                }
                if(index === 0)
                {
                    setSelectedTechnology(value.technology_name);
                    setDeviceCount(value.device_count);
                }
            }
            setTechnologiesData(technologies)
        });
    }, []);
    useEffect(() => {
        if(selectedTechnology)
        {
            eolService.getDeviceCount(selectedTechnology,params).then((result)=>{
                eolService.fetchDevices(selectedTechnology,offSet,params).then((response)=>{
                    setDeviceCount(result);
                    setPageLength(response.data.length);
                    setDeviceData(response.data);
                });
            })
        }
    }, [selectedTechnology,offSet,params]);
    useEffect(() => {
        if(selectedTechnology){
            eolService.getVersions(selectedTechnology).then((response)=>{
              // console.log(response);
                let versions = {}
                for (const[ ,value] of response.entries()){
                    versions={
                        ...versions,
                        [value.version]: value.device_count
                    }
                }
                setVersionsData(versions);
            })
        }
    }, [selectedTechnology]);

    return(
        <div>
            <div className={classes.optionsHeader}>
                <div 
                style={{
                    color:'white',
                    fontSize: '18px'
                }}>
                    <label for="tech">Choose Technology</label><br/>
                    <select 
                    value={selectedTechnology}
                    onChange={onTechChange}
                    id='tech'
                    style={{
                        background:"transparent",
                        color:"#fff", 
                        border:"1px solid rgb(118, 166, 238, 0.5)",
                    }}>
                        {technologiesData && Object.keys(technologiesData).map((tech, i) => {
                            return (
                                <option 
                                    value={tech}
                                    style={{
                                        background:"#052758", 
                                        color:"#fff",
                                    }}
                                    key={i}
                                >
                                    {commonService.capitalizeletterWith_(tech)}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div 
                style={{
                    color:'white',
                    fontSize: '18px'
                }}>
                    <label for="ver">Choose Version</label><br/>
                    <select 
                    value={selectedVersion}
                    onChange={onVersionChange}
                    id='ver'
                    style={{
                        background:"transparent",
                        color:"#fff", 
                        border:"1px solid rgb(118, 166, 238, 0.5)",
                    }}>
                        <option 
                            value={'all'}
                            style={{
                                background:"#052758", 
                                color:"#fff",
                            }}
                        >
                            All
                        </option>
                        {versionsData && Object.keys(versionsData).map((ver, i) => {
                            return (
                                <option 
                                    value={ver}
                                    style={{
                                        background:"#052758", 
                                        color:"#fff",
                                    }}
                                    key={i}
                                >
                                    {ver}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <input type="checkbox" id="ins" name="ins" checked={inSupportSelected} onChange={settingInSupportSelected}/>
                    <label for="ins">&nbsp;&nbsp;In Support</label>
                </div>
                <div>
                    <input type="checkbox" id="exp" name="exp" checked={expiredSelected} onChange={settingExpiredSelected}/>
                    <label for="exp">&nbsp;&nbsp;Expired</label>
                </div>
                <div>
                    <label for="startdate">Choose Start Date</label><br/>
                    <input type="date" id="startdate" name="startdate" value={startDate} onChange={settingStartDate}/>
                </div>
                <div>
                    
                    <label for="endDate">Choose End Date</label><br/>
                    <input type="date" id="endDate" name="endDate" value={endDate} onChange={settingEndDate}/>
                </div>
            </div>
            {selectedTechnology?
                <React.Fragment>
                <table className={classes.technologies}>
                    <tr>
                        <th>System Name</th>
                        <th>Version</th>
                        <th>End Of Life Date</th>
                        <th>Expired</th>
                    </tr>
                    {deviceData?
                        deviceData.length>0?
                            deviceData.map((device,index)=>(
                                <tr key={index}>
                                    <td>{device.system_name}</td>
                                    <td>{device.version}</td>
                                    <td>{device.end_of_life}</td>
                                    {device.expired?
                                    <td style={{color: '#E81B0E', fontWeight:'bold'}}>Expired</td>
                                    :<td style={{color: '#11B05C', fontWeight:'bold'}}>In Support</td>}
                                </tr>
                            ))
                        :<tr><td colSpan={4}>No Data Found</td></tr>
                    :null}
                </table>
                <div style={{display:'flex', alignItems:'center', marginTop:"10px"}}>
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={offSet<=0?true:false} 
                        size='small' 
                        onClick={handlePrev} 
                        className={classes.navButtons}
                    >
                        Prev
                    </Button>
                    <div 
                        style={{
                            color:'white',
                            padding:'0 10px'
                        }}
                    >
                        Showing {offSet+1}-{offSet+pageLength} of {deviceCount}
                    </div>
                    <Button 
                        variant='secondary' 
                        color='primary' 
                        disabled={(offSet+pageLength)>=deviceCount?true:false} 
                        size='small' 
                        onClick={handleNext}
                        className={classes.navButtons}
                    >
                        Next
                    </Button>
                </div>
            </React.Fragment>
            :<LoadingFull />}
        </div>
    );    
}

export default EndOfLifePage;