// import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { commonService } from '@Services';

function IncidentLineChart(props){
    const name = props.name ? commonService.capitalizeletterWith_(props.name) : "ANC"
    let data = null;
    let color = null;
    
    if(props.color){
        color = props.color
    }

    if(props.data)
    {
        const propsData = props.data;
        const propName = props.name;
        let arrangedData = [];
        for (const [index, value] of propsData.entries()) {
            const chartDatam = {
                name: String(value[propName]),
                value: value.device_count,
                index: index
            }
            arrangedData.push(chartDatam);
        }
        data = arrangedData;
    }

    function handleChartClick(params){
        // console.log(params);
          const filterBy = props.name;
          const filterValue = params.data.name;
          const totalCount = params.data.value;
          const filter = filterBy+'='+filterValue
          props.handleClick(filter, totalCount);
      }

    const option = {
        // tooltip: {
        //     trigger: 'item',
        //     // formatter: "{a} <br/>{b} : {c}({d}%)"
        //     formatter: function (params) {
      
        //         return `${name}<br />
        //                 <div style="background-color:${params.color}; height:10px; width:10px; border-radius:3px; display:inline-block"></div> ${params.name}: ${params.data.value} (${params.percent}%)<br />`;  
        //     },
        //     textStyle: {
        //         color : '#000',
        //         fontWeight: '600',
        //         fontSize: '1.2em'
        //     }
        // },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            top: '3%',
            left: '25%',
            orient: 'horizontal',
            textStyle: {
                color: '#fff',
                fontSize: 15,
                fontWeight: 400
            },
            borderColor: '#fff',
            borderWidth: 1,
            borderRadius: 10,
            padding: 10
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
              name: 'Incident Created',
              type: 'line',
              data: [220, 182, 191, 234, 290, 330]
            },
            {
              name: 'Incident Resolved',
              type: 'line',
              data: [150, 232, 201, 174, 280, 330]
            },
        ]
    };
      

    return(
        <div>
            <ReactEcharts 
                style={{
                    // height:'250px',
                    width:'100%'
                }} 
                option={option} 
                // onEvents={{'click':handleChartClick}} 
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:"1em",
                    fontWeight: 700
                }}>
                    {name}
            </p>
        </div>
        
    )
}
export default IncidentLineChart