import React, {useState, useEffect} from 'react';
import { deviceService, commonService, csvService } from '@Services';
import { Loading } from '@CommonComponents/Loading/Loading';
import Button from 'react-bootstrap/Button';
import DeviceModal from './components/DeviceModal';
import Details from './components/Details';
import classes from './DeviceList.module.css';
import {FaFileDownload} from 'react-icons/fa';
import FixitModal from './components/FixitModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function DeviceList(props){
    const techName = props.techName ?? '';
    const usecase = props.usecase ?? '';
    const usecaseScore = props.usecaseScore ?? 0;
    const systemName = props.systemName ?? '';
    const filterBy = props.filterBy ?? '';
    const showHeader = props.showHeader ?? false;
    const startDate = props.startDate ?? '';
    const endDate = props.endDate ?? '';
    const params = props.params ?? '';
    const totalCount = props.totalCount ?? undefined;
    const showAutomationCheckbox = props.showAutomationCheckbox ?? false;

    const [tableData, setTableData] = useState();
    const [offSet, setOffSet] = useState(0);
    const [totalDeviceCount,settotalDeviceCount] = useState(0);
    const [pageLength,setPageLength] = useState(0);
    const [modalData, setModalData] = useState();
    const [csvData, setcsvData] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [checkboxesState, setCheckboxesState] = useState([])
    // const [fixitModalValue, setFixitModalValue] = useState({})
    const [checkboxesShow, setCheckboxesShow] = useState({})
    const [fixitModalShow, setFixitModalShow] = useState(false);

    function handleNext() {
        const os = parseInt(offSet) + pageLength;
        setOffSet(os);
    }
    function handlePrev() {
        const os = parseInt(offSet) - pageLength;
        if(parseInt(os)<0)
            setOffSet(0);
        else
            setOffSet(os);
    }
    function showModal(state){
        setModalShow(state);
    }
    function handleTableClick(data){
        const systemNameFilter = commonService.classifyPrimaryKey(techName);
        const systemName = data[systemNameFilter];
        setModalData(systemName);
        showModal(true);
    }
    function checkAll(e) {
        let checkboxes = Array.from(document.getElementsByName("deviceCheckbox"))
        let obj = {}
        let arr = [];
        checkboxes.forEach(checkbox => {
            obj[checkbox.value.split(',')[2]] = e.target.checked;
            let ab = {
                "technology":checkbox.value.split(",")[0],
                "usecase":checkbox.value.split(",")[1],
                "hostname":checkbox.value.split(",")[2],
                "ip_address":checkbox.value.split(",")[3],
            }
            arr.push(ab);
        })
        // console.log(obj)
        setCheckboxesShow(obj);
        setCheckboxesState(arr);
    }
    function onDeviceCheck(e) {
        let checkboxes = Array.from(document.getElementsByName("deviceCheckbox"))
        let arr = [];
        checkboxes.forEach(checkbox => {
            if(checkbox.checked)
            {
                let ab = {
                    "technology":checkbox.value.split(",")[0],
                    "usecase":checkbox.value.split(",")[1],
                    "hostname":checkbox.value.split(",")[2],
                    "ip_address":checkbox.value.split(",")[3],
                }
                arr.push(ab);
            }
        })
        let checkedList = {[e.target.value.split(",")[2]]:e.target.checked}
        checkedList = {...checkboxesShow, ...checkedList}
        setCheckboxesShow(checkedList)
        setCheckboxesState(arr);
    }
    function downloadCSV(){
        // console.log('Clicked on Download');
        const fileName = "smc_report_"+techName.split('-')[0].toLowerCase()+"_"+techName.split('-')[1].toLowerCase();
        if(csvData)
        {
            csvService.makeCsv(csvData,fileName);
        }
    }

    useEffect(() => {
        if(totalCount)
        {
            settotalDeviceCount(totalCount);
        }
        else
        {
            deviceService.deviceCount(techName).then((deviceDetails)=>{
                Object.keys(deviceDetails).map((usecase,i)=>{
                    if(i===0)
                    {
                        const deviceCount = deviceDetails[usecase].device_count.critical+deviceDetails[usecase].device_count.low+deviceDetails[usecase].device_count.medium;
                        settotalDeviceCount(deviceCount);
                    }
                    return 0;
                })
            });
        }
    }, []);
    useEffect(() => {
        deviceService.fetchDevicesInsight(techName,offSet,usecase,startDate,endDate,filterBy,systemName,params).then((response)=>{
            let length = response.data.length;
            console.log(response)
            setPageLength(length);
            setTableData(response.data);
        });
    }, [offSet]);
    useEffect(() => {
        if(totalDeviceCount)
        {
            if(startDate)
            {
                deviceService.fetchTechDevicesWithLimit(techName,usecase,'all',startDate,endDate,filterBy,systemName,params,totalDeviceCount).then((response)=>{
                    let items = []
                    for (const [ , value] of response.entries()) {
                        const sysname = commonService.classifySystemName(techName);
                        let datam={
                            [sysname]: value[sysname]
                        }
                        if(value.hasOwnProperty('ip_address'))
                        {
                            datam={
                                ...datam,
                                ip_address: value['ip_address']
                            }
                        }
                        if(value.hasOwnProperty(usecase))
                        {
                            const usecaseComplianceScore = value[usecase]['compliance_score'] || value[usecase]['compliance_score']===0 ? value[usecase]['compliance_score']:"NA";
                            datam={
                                ...datam,
                                [usecase]: value[usecase]['data'],
                                score: usecaseComplianceScore
                            }
                        }
                        if(params)
                        {
                            const parameters = params.split('&');
                            for (const [ , val] of parameters.entries()) {
                                datam={
                                    ...datam,
                                    [val]: value[val]
                                }
                            }
                        }
                        items.push(datam);
                    }
                    setcsvData(items);
                    // setcsvData()
                })
            }
            else
            {
                deviceService.getTechDataStartDate(techName).then(startDate=>{
                    deviceService.fetchTechDevicesWithLimit(techName,usecase,'all',startDate,commonService.getNextQuarterDate(startDate),filterBy,systemName,params,totalDeviceCount).then((response)=>{
                        let items = []
                        for (const [ , value] of response.entries()) {
                            // items.push(<Element key={index} />)
                            const sysname = commonService.classifySystemName(techName);
                            let datam={
                                [sysname]: value[sysname]
                            }
                            if(value.hasOwnProperty('ip_address'))
                            {
                                datam={
                                    ...datam,
                                    ip_address: value['ip_address']
                                }
                            }
                            if(value.hasOwnProperty(usecase))
                            {
                                const usecaseComplianceScore = value[usecase]['compliance_score'] || value[usecase]['compliance_score']===0 ? value[usecase]['compliance_score']:"NA";
                                datam={
                                    ...datam,
                                    [usecase]: value[usecase]['data'],
                                    score: usecaseComplianceScore
                                }
                            }
                            if(params)
                            {
                                const parameters = params.split('&');
                                for (const [ , val] of parameters.entries()) {
                                    datam={
                                        ...datam,
                                        [val]: value[val]
                                    }
                                }
                            }
                            items.push(datam);
                        }
                        setcsvData(items);
                    })
                })
            }
        }
    }, [totalDeviceCount]);

    return (
        <div>
            {tableData?
                <React.Fragment>
                    <table className={classes.technologies}>
                        {showHeader?
                            <thead>
                                <tr style={{borderBottom: '1px solid #111', fontSize:"1.2em"}}>
                                    <th colSpan={5}>
                                        {commonService.technologyKnownAs(techName)} - {usecase?commonService.capitalizeletterWith_(usecase):null} {usecase?"          Score: "+usecaseScore+" %":null}
                                    </th>
                                </tr>
                            </thead>
                        :null}
                        <thead>
                            <tr >
                                {showAutomationCheckbox?
                                    <th style={{textAlign:"center"}}>
                                        <input 
                                            type="checkbox" 
                                            className={classes.checkbox}
                                            onChange={checkAll}
                                        />
                                    </th>
                                :null}
                                {Object.keys(tableData[0]).map((heading, i) => (
                                    <React.Fragment key={i}>
                                        {heading === commonService.classifySystemName(techName)?
                                            <th>
                                                {commonService.capitalizeletterWith_(heading)}
                                            </th>
                                        :null}
                                        {heading === 'ip_address'?
                                            <th>
                                                {commonService.capitalizeletterWith_('ip_address')}
                                            </th>
                                        :null}
                                        {heading === usecase?
                                            <React.Fragment>
                                            <th>
                                                {commonService.capitalizeletterWith_(usecase)}
                                            </th>
                                            <th>
                                                {commonService.capitalizeletterWith_('compliance_score')}
                                            </th>
                                            </React.Fragment>
                                        :null}
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody data-for='table-rows' data-tip='Click me for device detail' >
                            {tableData.map((data, i) => (
                                <tr key={i} onClick={()=>handleTableClick(data)}>
                                    {showAutomationCheckbox?
                                        <td onClick={(e) => e.stopPropagation()} style={{textAlign:"center"}}>
                                            <input 
                                                type="checkbox" 
                                                className={classes.checkbox}
                                                name="deviceCheckbox"
                                                value={[techName,usecase,data[commonService.classifyPrimaryKey(techName)],data['ip_address']]}
                                                checked={checkboxesShow[data[commonService.classifyPrimaryKey(techName)]] ?? false}
                                                onChange={onDeviceCheck}
                                            />
                                        </td>
                                    :null}
                                    <React.Fragment>
                                        {data.hasOwnProperty(commonService.classifySystemName(techName))?
                                            <td style={{cursor:"pointer"}}>
                                                {data[commonService.classifySystemName(techName)]}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty('ip_address')?
                                            <td>
                                                {data['ip_address']}
                                            </td>
                                        :null}
                                        {data.hasOwnProperty(usecase)?
                                            <React.Fragment>
                                            {data[usecase]['data']===null?
                                            <td>
                                                No Data Available
                                            </td>
                                            :Array.isArray(data[usecase]['data'])?
                                            <td onClick={(e) => e.stopPropagation()}>
                                                Total: {data[usecase]['data'].length} <br/>
                                                <Details property={'data'} data={ data[usecase]['data'] } tabbing={0} />
                                            </td>
                                            :typeof data[usecase]['data'] === 'object' && !Array.isArray(data[usecase]['data'])?
                                            <td onClick={(e) => e.stopPropagation()}>
                                                {Object.keys(data[usecase]['data']).map((detail, i)=>{
                                                    return <Details property={detail} data={ data[usecase]['data'][detail] } tabbing={0} key={i} />
                                                })}
                                            </td>
                                            :<td>
                                                {String(data[usecase]['data'])}
                                            </td>
                                            }
                                            <td>
                                                {data[usecase]['compliance_score'] || data[usecase]['compliance_score']===0?data[usecase]['compliance_score']:"NA"}
                                            </td>
                                            </React.Fragment>
                                        :null}
                                    </React.Fragment>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ReactTooltip id='table-rows' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099'  />
                    <div className={classes.tableFooter}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                variant='secondary' 
                                color='primary' 
                                disabled={offSet<=0?true:false} 
                                size='small' 
                                onClick={handlePrev} 
                                className={classes.navButtons}
                            >
                                Prev
                            </Button>
                            <div 
                                style={{
                                    color:'white',
                                    padding:'0 10px'
                                }}
                            >
                                Showing {offSet+1}-{offSet+pageLength} of {totalDeviceCount}
                            </div>
                            <Button 
                                variant='secondary' 
                                color='primary' 
                                disabled={(offSet+pageLength)>=totalDeviceCount?true:false} 
                                size='small' 
                                onClick={handleNext}
                                className={classes.navButtons}
                            >
                                Next
                            </Button>
                            <div style={{
                                    color:'white',
                                    padding:'0 10px'
                                }}>
                                <FaFileDownload onClick={downloadCSV} size={25} />
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            {showAutomationCheckbox && checkboxesState?
                            <Button 
                                variant='secondary' 
                                color='primary' 
                                size='small' 
                                onClick={()=>setFixitModalShow(true)} 
                                className={classes.navButtons}
                            >
                                Fix It
                            </Button>
                            :null}
                        </div>
                    </div>
                    {modalShow?
                        <DeviceModal 
                            show={modalShow} 
                            modalShow={showModal} 
                            systemName={modalData} 
                            techName={techName} 
                            startDate={startDate}
                        />
                    :null}
                    {fixitModalShow?
                        <FixitModal
                            show={fixitModalShow}
                            modalShow={setFixitModalShow}
                            data={checkboxesState}
                            techName={techName}
                        />
                    :null}
                </React.Fragment>
            :<Loading />}
        </div>
    );
}
export default DeviceList;