import classes from './Header.module.css';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import SessionReminder from './SessionReminder/SessionReminder';
import logo from '../../../../assets/SMClogo_2.png';

function Header() {
    return (
        <div className={classes.header}>
            <div className={classes.logoGroup}>
                <img src={logo} width="40px" height="40px" alt='SMC'/>
                <span>SMC 4.1</span>
            </div>
            <HeaderNavigation />
            <SessionReminder />
        </div>
    )
}
export default Header