import React from "react";
import {useRef, useState} from 'react';
import { userService } from '@Services';
import { useNavigate } from "react-router-dom";
import logo from '@Assets/SMClogo.png'

const LoginPage = (props) => {
  const navigate = useNavigate();
  const userInputRef = useRef();
  const passwordInputRef = useRef();
  const [loginError, setLoginError] = useState('');

  function loginHandler(event) {
      setLoginError('');
      event.preventDefault();
      const enteredUser = userInputRef.current.value;
      const enteredPassword = passwordInputRef.current.value;
      userService.login(enteredUser, enteredPassword)
        .then(
          user => {
          // console.log(user);
            props.onLogin(1);
            navigate('/');
          }
        ).catch( exp => {
          if(exp.message === "Request failed with status code 401")
            setLoginError("Invalid User/Password")
          else
            setLoginError(exp.message)
        });
  }

  return (
    <div className="limiter">
      {/* <div className="container-login100" style={{ backgroundImage: `url(/background.jpeg)` }}> */}
      <div
        className="container-login100"
        style={{ backgroundImage: `url(/background.jpeg)` }}
      >
        <div className="wrap-login100">
          <div className="wrap-login100-inner">
            <form className="login100-form validate-form" onSubmit={loginHandler}>
              <span className="login100-form-logo">
                <img src={logo} alt="SMC 4.1" width="200px" height="200px"/>
              </span>
              <span className="login100-form-title p-b-34 p-t-37">
                <div className="header">
                  SMC <span>4.1</span>
                  <br />
                  <div>Managing Security Proactively</div>
                </div>
              </span>
              <div className="place-holder">
              <div
                className="wrap-input100 validate-input"
                data-validate="Enter username"
              >
                <input
                  className="input100"
                  type="text"
                  name="username"
                  placeholder="Username"
                  autoComplete="off"
                  ref={userInputRef}
                />
                <span
                  className="focus-input100"
                  data-placeholder="&#xf207;"
                ></span>
              </div>
              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                  <input
                    className="input100"
                    type="password"
                    name="pass"
                    placeholder="Password"
                    ref={passwordInputRef}
                  />
                <span
                  className="focus-input100"
                  data-placeholder="&#xf191;"
                ></span>
              </div>
              </div>
              <div className="container-login100-form-btn">
                <button className="login100-form-btn">Login</button>
              </div>
            </form>
            {loginError?
            <div style={{textAlign: 'center', 
              fontSize: 18,
              color: 'red',
              marginTop: 0}}>{loginError}</div>:null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
